import React from "react";
import { Card, Col, Row, Typography, Button, Carousel } from "antd";
import { Images } from "../../images";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { GET_ALL_NOTIFICATIONS } from "../../api/endpoints";
import { call } from "../../api/axios";
import { useState, useEffect } from "react";

const OffersAndPromotions = ({ title }) => {
  const navigate = useNavigate();
  const [notifications, setnotifications] = useState([]);
  const getNotifications = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_NOTIFICATIONS, payload)
      .then((res) => {
        const arr = res?.data?.data;
        setnotifications(arr);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const offersCards = Array.from({ length: 2 }, (_, index) => index);

  return (
    <div>
      <Typography.Title level={3} className="we-2-call-homepage-sub-heading">
        {title}
        <div className="hr-yellow-line"></div>
      </Typography.Title>
      <Row className="mb-10 offers-and-promotions-main-card">
        <Col span={24}>
          <Carousel autoplay autoplaySpeed={3000}>
            {offersCards?.map((card, index) => (
              <Card key={index} className="br-20 offers-and-promotions-card">
                <Row>
                  <Col span={4}>
                    <div className="ad-wifi-image">
                      <img
                        src={Images.wifi_image}
                        alt="headphone-with-microphone"
                        className="w-100"
                      />
                    </div>
                  </Col>
                  <Col
                    span={20}
                    className="font-1rem flex-center meetings-content-container offers-description"
                  >
                    {notifications?.map((obj, index) => (
                      <div key={index}>
                        {obj?.status === true && (
                          <li className="notifi">
                            {obj?.website_name === "www.we2call.com" &&
                              obj?.notification_place === "annocement poster" &&
                              obj?.description}
                          </li>
                        )}
                      </div>
                    ))}
                  </Col>
                </Row>
              </Card>
            ))}
          </Carousel>
        </Col>
      </Row>
      <Row className="tours-and-tournaments-main-card">
        <Col span={24}>
          <Carousel autoplay autoplaySpeed={3000}>
            {offersCards?.map((card, index) => (
              <Card key={index} className="br-20 tours-and-tournaments-card">
                <Row>
                  <Col span={14} className="meetings-content-container">
                    <div className="tours-and-tournament">
                      <div className="font-1rem offers-description">
                        Tours & Tournaments
                      </div>
                      <p className="mt-5 mb-0">
                        Enjoy unlimited fun and Win more money.
                      </p>
                      <Button
                        className="flex-center offers-button mt-5"
                        onClick={() => navigate("/tours-and-tournaments")}
                      >
                        <HiOutlineArrowNarrowRight size={24} />
                      </Button>
                    </div>
                  </Col>
                  <Col span={7} className="flex-center">
                    <div className="ad-image">
                      <img
                        src={Images.travel_banner}
                        alt="headphone-with-microphone"
                        className="w-100"
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};

export default OffersAndPromotions;
