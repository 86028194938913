import React,{useEffect,useState} from "react";
import { Row, Col, Card, Button, Carousel } from "antd";
import { Images } from "../../images";
import { MdOutlineCalendarMonth, MdPhoneInTalk } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { call } from "../../api/axios";
import { GET_ALL_MEETINGS } from "../../api/endpoints";
const We2CallContent = () => {
  const navigate = useNavigate();
  const handleW2CallButton = () => {
    navigate("/meetings");
  };
   const registerId = localStorage.getItem("register_id");
  const [meetingsCompleted, setMeetingsCompleted] = useState([]);
  const [meetingsUpcoming, setMeetingsUpcoming] = useState([]);
  const we2callCards = Array.from({ length: 4 }, (_, index) => index);


  const getAllMatchesData = async () => {
    await call(GET_ALL_MEETINGS, {
      register_id: registerId,
    }).then((res) => {
      if (res.data.statusCode === 200) {
        const currentTimeStamp = Date.now();
        const upcomingMeetings = [];
        const completedMeetings = [];
        res?.data?.data?.forEach((item) => {
          const createdTimeStamp = item.created_time_stamp;
          const timeDifference = currentTimeStamp - createdTimeStamp;
          const isWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;
          if (!item.completed_status && isWithin24Hours) {
            upcomingMeetings.push(item);
          } else {
            completedMeetings.push(item);
          }
        });
        setMeetingsCompleted(completedMeetings);
        setMeetingsUpcoming(upcomingMeetings);
      }
    });
  };
  useEffect(() => {
    getAllMatchesData();
  }, []);

  return (
    <div>
      <Row gutter={[16, 16]} className="we2-call-card">
        <Col xl={12} lg={14} md={24}>
          <Carousel autoplay autoplaySpeed={3000}>
            {we2callCards?.map((card, index) => (
              <Card key={index} className="we-2-call-left-card br-20">
                <div className="we-2-call-left-card-content-container">
                  <div className="w-40 we-2-call-card-left">
                    <div className="fw-400 confidence-text we-2-call-heading mb-5 mt-0">
                      Connect with
                      <br />
                      <b>Confidence & Secure</b>
                    </div>
                    <p className="we-2-call-description mt-0">
                      Amet, consectetur adipiscing elit, sed do eiusmod tempor.
                    </p>
                    <Button
                      className="flex-center we-2-call-button custom-we2-call-btn"
                      onClick={() => handleW2CallButton()}
                    >
                      We2Call
                    </Button>
                  </div>
                  <div className="we-2-call-image-container">
                    <img
                      src={Images.we2call_banner}
                      alt="we-2-call-banner"
                      className="we-2-call-banner-img"
                    />
                  </div>
                </div>
              </Card>
            ))}
          </Carousel>
        </Col>
        <Col xl={6} lg={5} md={12}>
          <Card className="br-20 upcoming-meetings-container flex-column">
            <Button
              className="flex-center we-2-call-meetings-btn w-100 mb-10"
              icon={<MdOutlineCalendarMonth size={20} />}
              style={{ width: "100%", overflowWrap: "break-word" }}
            >
              21 June, Wed 10:30 PM
            </Button>
            <Row className="flex-space-between" gutter={[16, 16]}>
              <Col span={7}>
                <div>
                  <img
                    src={Images.headphone_with_microphone}
                    alt="headphone-with-microphone"
                    className="w-100"
                  />
                </div>
              </Col>
              <Col span={16} className="meetings-content-container">
                <div className="meetings-heading mb-5">Upcoming Meetings</div>
                <div className="meetings-heading completed-meetings-count mb-10">
                  {meetingsUpcoming.length}
                </div>
                <Button
                  className="meetings-button flex-center"
                  onClick={() => navigate("/meetings/")}
                >
                  Join Now
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xl={6} lg={5} md={12}>
          <Card className="br-20 completed-meetings-container flex-column">
            <Button
              className="w-100 flex-center we-2-call-meetings-btn w-100 mb-10"
              icon={<MdPhoneInTalk size={20} />}
            >
              <div className="flex-space-between w-100">
                <div>Call Duration</div>
                <div>1000 min</div>
              </div>
            </Button>
            <Row className="flex-space-between" gutter={[16, 16]}>
              <Col span={7}>
                <div>
                  <img src={Images.call_image} alt="phone" className="w-100" />
                </div>
              </Col>
              <Col span={16} className="meetings-content-container">
                <div className="meetings-heading mb-5">Completed Meetings</div>
                <div className="meetings-heading completed-meetings-count mb-10">
                  {meetingsCompleted.length}
                </div>

                <Button
                  className="meetings-button flex-center"
                  onClick={() => navigate(`/meetings/${"completed"}`)}
                >
                  History
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default We2CallContent;
