import React from "react";
import { Avatar, Col, Modal, Row } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { CameraOutlined } from "@ant-design/icons";
import { ImAttachment } from "react-icons/im";
import { BiMicrophone } from "react-icons/bi";
import { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import "./style.css";
import { NoChatting } from "./NoChatting";
import { GET_USER_MESSAGES, GET_ADMIN_DETAILS, GENERATE_SIGNED_URL } from "../../api/endpoints";
import { call, Signed } from "../../api/axios";
import { open, send } from "../../utils/WebSocket";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";

const ChatPage = ({ showChatPage, setShowChatPage }) => {
  const register_id = localStorage?.getItem("register_id");
  const creator_id = localStorage?.getItem("creator_id");
  const [userInput, setUserInput] = useState("");
    const [selectedImage, setSelectedImage] = useState(null); 
  const [webcamVisible, setWebcamVisible] = useState(false);
  const webcamRef = useRef(null);
  const ImageBaseUrl = "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";
  const [chatMessages, setChatMessages] = useState([]);
  const [trnxId,setTrxId] =useState(null);
  const [adminData, setAdminData] = useState();
  const [singedUrl, setSignedUrl] = useState(false);
  const [profileImage, setProfileImage] = useState("");


  const getAllAdminsData = async () => {
    await call(GET_ADMIN_DETAILS, {
      register_id: creator_id,
    })
      .then((res) => {
        setAdminData(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllAdminsData();
  }, []);

  const inputHandler = async () => {
    if (profileImage) {
       await imageUpploadToBucket();
      const imageUrl = `${ImageBaseUrl}/${"chat-images"}/${register_id}/${profileImage.name}`;
      await send(imageUrl, "text", creator_id);
      setUserInput("");
      setProfileImage(null);
    } else if (userInput.trim() !== "") {
      await send(userInput, "text", creator_id);
      setUserInput("");
    }
  };

  const addMessage = (message, msg_c = 0) => {
    let temp = { ...message, ts: new Date().getTime(), msg_c };
    setChatMessages((prev) => {
      if (!Array.isArray(prev)) {
        return [temp];
      }
      return [...prev, temp];
    });
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const hanldeKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftyKey) {
      event.preventDefault();
      inputHandler();
    }
  };

  const getAllUserMessages = async () => {
    await call(GET_USER_MESSAGES, {
      from_user_id: localStorage?.getItem("creator_id"),
      to_user_id: localStorage?.getItem("register_id"),
    })
      .then(async (res) => {
        setChatMessages(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateSignedUrl = async (filename) => {
    const trxId = new Date().getTime();
    setTrxId(trxId);
    await call(GENERATE_SIGNED_URL, {
      register_id,
      event_type: "user_profile_image",
      folder_name: "chat-images",
      file_name: filename,
    })
      .then(async (res) => {
        let url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageUpploadToBucket = async () => {
    singedUrl &&
      profileImage &&
      (await fetch(singedUrl, {
        method: "PUT",
        body: profileImage,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
      .then((res) => {
        console.log("imageUpploadToBucket called:", res);
        return true;
      })
        .catch((err) => {
          console.log(err);
        }));
  };

  const onMessageRecieve = (event) => {
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    if (creator_id === msg?.to_user_id || creator_id === msg?.from_user_id) {
      addMessage(msg);
    }
  };

  useEffect(() => {
    getAllUserMessages();
  }, []);

  useEffect(() => {
    open({ onmessage: onMessageRecieve });
  }, [chatMessages]);

  const toggleWebCam = () => {
    setWebcamVisible((prevVisible) => !prevVisible);
  };
  const captureSnapshot = () => {
    const imgSrc = webcamRef.current.getScreenshot();
    setWebcamVisible(false);
  };

  const uploadfileInputRef = useRef(null);
  const handleUploadFileSelect = (e) => {
    const file = e?.target?.files[0];
    if (file) {
      const filename = file.name;
      setProfileImage(file);
      generateSignedUrl(filename);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

   const handleUploadButtonClick = () => {
    uploadfileInputRef.current.click();
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  return (
    <Modal className="chat-page-position unselectable-text" centered open={showChatPage} footer={null} onCancel={() => setShowChatPage((prev) => !prev)}>
      <div className="chat-page-bg chat-page-position border-yellow-chat br-10">
        <Row gutter={[16, 16]}>
          <Col sm={24} lg={24} xl={24}>
            <div className="flex-space-between ptrl-10 pos-relative">
              <div className="flex-start">
                <div className="flex-center mr-10 w-30">
                  <img src={adminData?.profile_image} alt="pic" className="avatar-container profile-image" />
                </div>
                <div className="clr-white">{adminData?.user_name}</div>
              </div>
              <div
                className="flex-center cursor-pointer cross-position mt-5"
                onClick={() => {
                  setShowChatPage(false);
                }}
              >
                <RxCross2 className="font-12" />
              </div>
            </div>

            {console.log(chatMessages)}
            <hr className="chat-hr-line" />
            <div className="chat-messages-container chat-height p-10">
              {chatMessages?.length > 0 ? (
  <>
    {chatMessages.map((message, index) => {
      // Format message timestamp
      const messageDate = moment(message.ts).format("YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");
      const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

      // Get previous message date for comparison
      const prevMessageDate = index > 0 ? moment(chatMessages[index - 1].ts).format("YYYY-MM-DD") : null;

      // Determine if we need to display the date
      const displayDate = messageDate !== prevMessageDate;

      // Convert to "Today" or "Yesterday" if applicable
      const displayDateText = messageDate === today ? "Today" :
                              messageDate === yesterday ? "Yesterday" :
                              moment(message.ts).format("MMMM DD, YYYY");

      return (
        <div key={index}>
          {/* Display date in the middle if it changes */}
          {displayDate && (
            <div className="text-center font-weight-bold mt-3 mb-2 date-text">
              {displayDateText}
            </div>
          )}

          {/* Display the message */}
          <div className={`chat-message w-100 ${message?.from_user_id === register_id ? "outgoing-message" : "incoming-message"} `}>
            <div className="mw-60">
              <div className={`mt-5 ${message?.from_user_id === register_id ? "outgoing-message-content" : "incoming-message-content"}`}>
                {typeof message.message === "string" && message.message.includes("http") ? (
                  <img className="w-100" src={message?.message} alt="chat-image"
                  onClick={() => setSelectedImage(message?.message)} />
                ) : typeof message.message !== "object" && !Array.isArray(message.message) ? (
                  <div>{message.message}</div>
                ) : (
                  <></>
                )}
              </div>
              <div className="message-time">{moment(message.ts).format("hh:mm a")}</div>
            </div>
          </div>
        </div>
      );
    })}
  </>
) : <NoChatting />}

              {/* {chatMessages?.length > 0 ? (
                <>
                  {chatMessages.map((message, index) => (
                    <div key={index} className={`chat-message w-100 ${message?.from_user_id === register_id ? "outgoing-message" : "incoming-message"} `}>
                      <div className="mw-60">
                        <div className={`mt-5 ${message?.from_user_id === register_id ? "outgoing-message-content" : "incoming-message-content"}`}>
                          {typeof message.message === "string" && message.message.includes("http") ? <img className="w-100" src={message?.message} alt="chat-image" /> : typeof message.message !== "object" && !Array.isArray(message.message) ? <div>{message.message}</div> : <></>}
                        </div>
                        <div className="message-time">{moment(message.ts).format("hh:mm a")}</div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <NoChatting />
              )} */}
            </div>
            <hr className="chat-hr-line" />
            <div className="p-10 mb-10 flex-start">
              <div className="w-60 message-send-container flex">
                <textarea
                  name="postMessage"
                  rows={1}
                  className="send-text-area font-14"
                  autoFocus
                  placeholder="Message..."
                  value={userInput}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  onKeyDown={(e) => userInput && hanldeKeyDown(e)}
                ></textarea>
                <button className="flex send-icon" onClick={() => inputHandler()}>
                  <SendOutlined />
                </button>
              </div>
              <div className="w-40 flex-space-evenly">
                <Avatar icon={<CameraOutlined className="font-12" />} className="flex-center cursor-pointer" onClick={() => toggleWebCam()} />
                <label htmlFor="fileInput" onClick={handleUploadButtonClick}>
                  <Avatar icon={<ImAttachment className="font-12" />} className="flex-center cursor-pointer" />
                  <input id="fileInput" type="file" ref={uploadfileInputRef} style={{ display: "none" }} onChange={handleUploadFileSelect} />
                </label>
                <Avatar icon={<BiMicrophone className="font-12" />} className="flex-center cursor-pointer" />
              </div>
              {/* {selectedImage && (
            <div className="image-modal" onClick={() => setSelectedImage(null)}>
              <div className="modal-content">
                <img src={selectedImage} alt="Zoomed chat" />
              </div>
            </div>
          )} */}
              {webcamVisible && (
                <div className="webcam-container">
                  <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" className="webcam-preview cursor-pointer" />

                  <div className="buttons-container">
                    <button className="webcam-toggle-btn mr-10 cursor-pointer" onClick={() => captureSnapshot()}>
                      capture
                    </button>
                    <button className="webcam-toggle-btn cursor-pointer" onClick={() => toggleWebCam()}>
                      close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default ChatPage;
