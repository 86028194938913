import React from "react";
import { Modal } from "antd";
import { Images } from "../../images";

function ThankyouPopup(props) {
  const { openThankyouModal, setOpenThankyouModal, displayData } = props;
  const handleCancel = () => {
    setOpenThankyouModal(false);
  };
  return (
    <Modal
      className="login-modal"
      centered
      open={openThankyouModal}
      footer={null}
      onCancel={() => handleCancel()}
    >
      <center>
        <img
          className="thankyou-image"
          src={Images?.ThumbsUpImage}
          alt="ThankYou"
        />
        <div className="font-16 mt-20">{displayData}</div>
        <div className="login-btn mt-20" onClick={() => handleCancel()}>
          Back To Home
        </div>
      </center>
    </Modal>
  );
}

export default ThankyouPopup;
