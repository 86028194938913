import React from "react";
import { Card, Col, Row, Carousel } from "antd";
import Typography from "antd/es/typography/Typography";
import { Images } from "../../images";
import { GET_ALL_NOTIFICATIONS } from "../../api/endpoints";
import { call } from "../../api/axios";
import { useState, useEffect } from "react";

const TrustedPlatforms = ({ title }) => {
  const [notifications, setnotifications] = useState([]);
  const getNotifications = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_NOTIFICATIONS, payload)
      .then((res) => {
        const arr = res?.data?.data;
        setnotifications(arr);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const platformCards = Array.from({ length: 2 }, (_, index) => index);

  return (
    <div>
      <Typography.Title level={3} className="we-2-call-homepage-sub-heading">
        {title}
        <div className="hr-yellow-line"></div>
      </Typography.Title>
      <Row className="website-advertisement-main-card">
        <Col span={24}>
          <Carousel autoplay autoplaySpeed={3000}>
            {platformCards?.map((card, index) => (
              <Card
                key={index}
                className="platforms-advertisements-container d-flex-column br-20"
              >
                <img
                  src={Images.game_image_01}
                  alt="game-01"
                  className="game-image-one"
                />
                <img
                  src={Images.game_image_02}
                  alt="game-02"
                  className="game-image-two"
                />
                <center>
                  <img src={Images.header_logo} alt="we2-call-logo" />
                </center>
                <p className="advertisement-website-info">
                  <div>
                    {notifications?.map((obj, index) => (
                      <div key={index}>
                        {obj?.status === true && (
                          <li className="notifi">
                            {obj?.notification_place === " website poster" &&
                              obj?.description}
                          </li>
                        )}
                      </div>
                    ))}
                  </div>
                </p>
              </Card>
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};

export default TrustedPlatforms;
