import React from "react";
import { Images } from "../../images";

function ChatButton({ setShowChatPage }) {
  return (
    <div
      className="flex msg-container cursor-pointer"
      onClick={() => setShowChatPage((prev) => !prev)}
    >
      <img src={Images.chat_icon} alt="chat" />
      <span className="ml-10">Chat with Us</span>
    </div>
  );
}

export default ChatButton;
