import React from "react";
import { Images } from "../../images";

export const NoChatting = () => {
  return (
    <div className="no-chat-page-container">
      <div>
        <img src={Images.no_chat_image} alt="no chat" className="w-100" />
      </div>
      <p className="clr-white font-12 flex-center">
        You haven’t received any message yet!
      </p>
    </div>
  );
};
