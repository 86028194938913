import Sockette from "sockette";
import { chat_base_url } from "../config/config_local";
import { call } from "../config/axios";
import {
  REQUEST_JOINED_MEETING
} from "../config/endpoints";

let ws;
let events;
let isConnecttionOpen = false;
export let openConnections = {};


export const open = (events = {}) => {
  events = events;
  return new Promise((resolve) => {
    ws = new Sockette(
      `wss://66zc1ns3z9.execute-api.ap-southeast-1.amazonaws.com/production?userid=${localStorage?.getItem(
        "register_id"
      )}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => {
          isConnecttionOpen = true;
          resolve();
        },
        onmessage: (e) => console.log("Message Received:", e),
        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => console.log("Stop Attempting!", e),
        onclose: (e) => {
          isConnecttionOpen = false;
        },
        onerror: (e) => console.log("Error:", e),
        ...events,
      }
    );
  });
};

export const send = async (data, messageType, toUser) => {
  if (!isConnecttionOpen) {
    await open(events);
  }
  // ws.send(
  //   JSON.stringify({
  //     action: "onmessage",
  //     message,
  //     imageUrl,
  //     from_user: localStorage?.getItem("register_id"),
  //     to_user: toUser,
  //   })
  // );
  let payload;
  if (messageType === "text") {
    payload = {
      action: "onmessage",
      message: data,
      from_user: localStorage?.getItem("register_id"),
      to_user: toUser,
    };
  } else if (messageType === "image") {
    payload = {
      action: "onmessage",
      message: data,
      from_user: localStorage?.getItem("register_id"),
      to_user: toUser,
    };
  }

  ws.send(JSON.stringify(payload));
};


export const close = () => {
  ws.close();
};

export const openrequestJoin = (events = {}) => {
  events = events;
  const userId = localStorage?.getItem("register_id");
  return new Promise((resolve, reject) => {
    ws = new Sockette(
      `${chat_base_url}?userid=${localStorage?.getItem("register_id")}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => {
          console.log("onopen : ", e);
          openConnections[userId] = ws;
          isConnecttionOpen = true;
          resolve();
        },
        onmessage: (e) => {
          console.log("Join request received:", e);
          try {
            const message = JSON.parse(e.data);
            if (events.onmessage) {
              events.onmessage(message);
            }
          } catch (error) {
            console.error("Error parsing message:", error);
          }
        },

        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => {
          console.log("Stop Attempting!", e);
          reject(new Error("Maximum attempts reached"));
        },
        onclose: (e) => {
          console.log("Closed!", e);
          isConnecttionOpen = false;
        },
        onerror: (e) => {
          console.log("Error:", e);
          reject(new Error("WebSocket error"));
        },
        ...events,
      }
    );
  });
};

export const sendRequestJoin = async (data, toUser, events,matchId) => {
  if (!isConnecttionOpen) {
    await open(events);
  }
    const payload = {
      action: "onmessage",
      message: data,
      from_user: localStorage?.getItem("register_id"),
      from_user_name: localStorage?.getItem("first_name"),
      to_user: toUser,
      match_id:matchId
    };
  ws.send(JSON.stringify(payload));
  await call(REQUEST_JOINED_MEETING, {
      payload
    })
      .then(async (res) => {
        const data = res.data?.data?.Items?.[0];
        console.log("live", data);
        if (res.status === 200) {
          console.log('Lambda function hit successfully', res.data);
        } else {
          console.error('Failed to hit Lambda function', res.status);
        }
      })
      .catch((err) => {
        throw err;
      });
};

export const OpenRequest3rdMethod = async ( matchId) => {
  try {
    const payload = {
      action: "onOpenParticipant",
      from_user: localStorage?.getItem("register_id"),
      match_id: matchId,
    };

    const res = await call(REQUEST_JOINED_MEETING, { payload });

    console.log(res,'prticpent');
    if (res.status === 200) {
       const parsedBody = JSON.parse(res.data?.body || '[]');
  console.log("participent live", parsedBody);

  if (parsedBody.length > 0) {
    return parsedBody.map(item => ({
    from_user_name: item.from_user_name,
    from_user_id: item.from_user_id,
    account_role: 'Participant',
    match_id: item.match_id,
    accepted: item.accepted,  
    message: item.message,
    timestamp: item.timestamp,
    to_user: item.to_user,
  }));
      } else {
        
        return [];
      }
    } else {
      console.error('Failed to hit Lambda function', res.status);
      return [];
    }
  } catch (error) {
    console.error('Error during OpenRequest3rdMethod:', error);
    return [];
  }
};