import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { FaShareFromSquare } from "react-icons/fa6";
import { MdContentCopy } from "react-icons/md";
import { call } from "../../api/axios";
import { GET_USER_INFO } from "../../api/endpoints";
import ShareButtons from "./ShareButtons";

function ShareDetailsPopup(props) {
  const { openShareModal, setOpenShareModal } = props;
  const register_id = localStorage?.getItem("register_id");
  const [showShareSection, setShowShareSection] = useState(false);
  const [inputData, setInputData] = useState({});
  const [copy, setCopy] = useState("Copy");
  const WEBSITE_URL = "www.we2call.com";
  const handleCancel = () => {
    setOpenShareModal(false);
  };
  const handleShowShareButton = (e) => {
    e.preventDefault();
    setShowShareSection(true);
  };
  const handleCopyText = () => {
    navigator.clipboard.writeText(WEBSITE_URL);
    setCopy("Copied");
  };
  const getUserProfile = async () => {
    await call(GET_USER_INFO, {
      register_id,
    }).then((resp) => {
      if (resp.data.status === 200) {
        setInputData({
          refferal_code: resp?.data?.data?.refferal_code,
          admin_refferal_code: resp?.data?.data?.admin_refferal_code,
          creator_id: resp?.data?.data?.creator_id,
        });
      }
    });
  };

  useEffect(() => {
    if (register_id) {
      getUserProfile();
    }
  }, []);
  return (
    <Modal
      open={openShareModal}
      className="login-modal"
      centered
      footer={null}
      onCancel={() => handleCancel()}
    >
      <center>
        <div className="we-2-call-heading">Share Details</div>
        <div className="mt-10">
          <label className="white-font flex font-10">Refer/Share ID</label>
          <div className="flex by-id-btn p-5 font-10 mt-5">
            <input
              className="h-35px login-inputs ml-5"
              type="email"
              placeholder="Refer/Share ID"
              disabled
              value={inputData.refferal_code}
            />
          </div>
        </div>
        <div className="mt-10">
          <label className="white-font flex font-10">Meeting Head ID</label>
          <div className="flex by-id-btn p-5 font-10 mt-5">
            <input
              className="h-35px login-inputs ml-5"
              type="email"
              placeholder="Meeting Head ID"
              disabled
              value={inputData.creator_id}
            />
          </div>
        </div>
        <div className="mt-10">
          <label className="white-font flex font-10">Website URL</label>
          <div className="flex by-id-btn p-5 font-10 mt-5">
            <input
              className="h-35px login-inputs ml-5"
              type="email"
              placeholder="WEBSITE_URL"
              disabled
              value={WEBSITE_URL}
            />
          </div>
        </div>
        <div className="flex-space-around">
          <div
            className="login-btn cancel-button flex-center mt-20 w-45 mr-5 ml-5 cursor-pointer"
            onClick={(e) => handleCopyText(e)}
          >
            <MdContentCopy className="mr-10 clr-yellow" />
            <div className={copy === "Copied" ? "clr-red" : ""}>{copy}</div>
          </div>
          <div
            className="login-btn cancel-button mt-20 w-45 mr-5 ml-5 cursor-pointer"
            onClick={(e) => handleShowShareButton(e)}
          >
            <FaShareFromSquare className="mr-10 clr-grn" />
            Share
          </div>
        </div>
        {showShareSection && <ShareButtons data={WEBSITE_URL} />}
      </center>
    </Modal>
  );
}

export default ShareDetailsPopup;
