import React from "react";

import { useEffect, useState } from "react";
import CompletedMeetings from "./CompletedMeetings";
import UpcomingMeetings from "./UpcomingMeetings";
import { GET_ALL_MEETINGS } from "../../api/endpoints";
import { call } from "../../api/axios";
import "./styles.css";
import { useParams } from "react-router-dom";
function Meetings() {
  const registerId = localStorage.getItem("register_id");
  const completed = useParams();
  const [meetingsCompleted, setMeetingsCompleted] = useState([]);
  const [meetingsUpcoming, setMeetingsUpcoming] = useState([]);
  const Buttons = ["Upcoming /Live Meetings", "Completed Meetings"];
  const [activeIndex, setActiveIndex] = useState(
    completed?.id === "completed" ? 1 : 0
  );
  const handleButtons = (index) => {
    setActiveIndex(index);
  };

  const getAllMatchesData = async () => {
    await call(GET_ALL_MEETINGS, {
      register_id: registerId,
    }).then((res) => {
      if (res.data.statusCode === 200) {
        const currentTimeStamp = Date.now();
        const upcomingMeetings = [];
        const completedMeetings = [];
        res?.data?.data?.forEach((item) => {
          const createdTimeStamp = item.created_time_stamp;
          const timeDifference = currentTimeStamp - createdTimeStamp;
          const isWithin24Hours = timeDifference <= 24 * 60 * 60 * 1000;
          if (!item.completed_status && isWithin24Hours) {
            upcomingMeetings.push(item);
          } else {
            completedMeetings.push(item);
          }
        });
        setMeetingsCompleted(completedMeetings);
        setMeetingsUpcoming(upcomingMeetings);
      }
    });
  };
  useEffect(() => {
    getAllMatchesData();
  }, []);

  return (
    <div>
      <div className="meetings-container">
        <div className="font-24 fw-800">Meetings</div>
        <div className="w-40 flex-space-between mt-10">
          {Buttons?.map((btn, index) => (
            <div
              key={index}
              className={
                activeIndex === index
                  ? "hilight-button font-14 cursor-pointer"
                  : "normal-button font-14 cursor-pointer"
              }
              onClick={() => handleButtons(index)}
            >
              {btn}
            </div>
          ))}
        </div>
      </div>
      <hr className="hr-line mt-20" />
      {activeIndex === 0 && (
        <UpcomingMeetings meetingsUpcoming={meetingsUpcoming} />
      )}
      {activeIndex === 1 && (
        <CompletedMeetings meetingsCompleted={meetingsCompleted} />
      )}
    </div>
  );
}

export default Meetings;
