
import React from "react";
import { Images } from "../../images";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { LOGIN_API } from "../../api/endpoints";
import { call } from "../../api/axios";
import "./styles.css";
import { Modal } from "antd";
import { useState } from "react";

function LoginPopup(props) {
  const { loginModal, setLoginModal } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [loginFormData, setLoginFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState();
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleCancel = () => {
    setLoginModal(false);
  };
  const handleChange = (e) => {
    setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
  };

  const handleSubmitLogin = async () => {
    if (!loginFormData.user_name) {
      return setError("User Id is required");
    }
    if (!loginFormData.password) {
      setError("Password is required");
    }
    setIsProcessing(true);

    await call(LOGIN_API, {
      user_name: loginFormData.user_name,
      password: loginFormData.password,
    })
      .then((res) => {
        setIsProcessing(false);
        if (res.data.status === 200) {
          localStorage.setItem("register_id", res?.data?.data?.register_id);
          localStorage.setItem("creator_id", res?.data?.data?.creator_id);
          localStorage.setItem("account_role", res?.data?.data?.account_role);
          localStorage.setItem("user_name", res?.data?.data?.user_name);
          localStorage.setItem("refferal_code", res?.data?.data?.refferal_code);
          localStorage.setItem("creator_name", res?.data?.data?.creator_name);
          window.location.reload();
          setLoginModal(false);
        } else {
          setIsProcessing(false);
          return setError(res.data.message);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        return setError(err?.message ? err?.message : "Network error");
      });
  };
  return (
    <div className="overlay">
      <Modal
        className="login-modal"
        centered
        open={loginModal}
        footer={null}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        {isProcessing === true && <div className="loading-spinner"></div>}
        <center>
          <div className="font-18 fw-600">Login</div>
          <form>
            <div className="mt-5">
              <label className="flex font-10">Username</label>
              <div className="flex by-id-btn p-5 font-10 mt-5">
                <img
                  className="username-img"
                  src={Images.LoginUserIcon}
                  alt="User_Icon"
                />
                <input
                  className="login-inputs ml-5"
                  type="email"
                  placeholder="Enter Your ID"
                  name="user_name"
                  value={loginFormData.user_name || ""}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="mt-5">
              <label className="flex font-10">Password</label>
              <div className="flex-space-between by-id-btn p-5 font-10 mt-5">
                <div className="flex-center">
                  <img
                    className="username-img"
                    src={Images.LoginLockIcon}
                    alt="Password_Icon"
                  />
                  <input
                    className="login-inputs ml-5"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter Your Password"
                    name="password"
                    value={loginFormData.password || ""}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div
                  className="flex font-18 mr-5 cursor-pointer"
                  onClick={() => handleShowPassword()}
                >
                  {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
                </div>
              </div>
            </div>
            <span className="clr-red">{error}</span>
            <div
              className="login-btn mt-10 cursor-pointer"
              onClick={() => handleSubmitLogin()}
            >
              {isProcessing === true ? "Processing..." : "Login"}
            </div>
          </form>
        </center>
      </Modal>
    </div>
  );
}

export default LoginPopup;
