export const Images = {
  weLogo: "../assets/logo_we.png",
  profileIcon: "../assets/profile_icon.png",
  copyIcon: "/assets/copy_Icon.png",
  cskIcon: "../assets/csk_Icon.png",
  miIcon: "../assets/mi_Icon.png",
  header_logo: "./assets/header_logo.png",
  profile: "../assets/profile.png",
  we2call_banner: "../assets/banner.png",
  we2call_logo: "../assets/we_2_call_logo.png",
  we2call_men_banner: "../assets/banner_men.png",
  headphone_with_microphone: "../assets/headphone_with_microphone.png",
  call_image: "../assets/call.png",
  wifi_image: "../assets/wifi.png",
  travel_banner: "../assets/travel_banner.png",
  game_image_01: "../assets/game_01.png",
  game_image_02: "../assets/game_02.png",
  star_icon: "../assets/star_icon.png",
  NoMeetingBG: "../assets/noMeeting_bg.png",
  chat_icon: "../assets/chat_icon.png",
  LoginUserIcon: "../assets/login_User_Icon.png",
  LoginLockIcon: "../assets/login_Lock_Icon.png",
  dhawan_image: "../assets/dhawan.png",
  sachin_image: "../assets/sachin.png",
  kohli_image: "../assets/kohli.png",
  dhoni_image: "../assets/dhoni.png",
  rohit_image: "../assets/rohit.png",
  raina_image: "../assets/raina.png",
  no_chat_image: "../assets/no_chat_image.png",
  edit_profile_image: "../assets/edit_profile_pic.png",
  LoginIcon: "../assets/login_icon.png",
  DarkModeColour: "../assets/dark_mode_colour.png",
  Settings: "../assets/Settings.png",
  Share: "../assets/Share.png",
  AddAccount: "../assets/add_Account.png",
  EditProfile: "../assets/edit_Profiles_Icon.png",
  Logout: "../assets/log_Out.png",
  ResetPassword: "../assets/reset_Password.png",
  BackArrow: "../assets/back_arrow.png",
  CalenderImg: "../assets/calender_icon.png",
  PlayIcon: "../assets/play_icon.png",
  DeleteIcon: "../assets/delete_icon.png",
  Banner: "../assets/Banner_Illustration.png",
  UserManagement: "../assets/Illustration_02.png",
  SportsManagement: "../assets/Illustration_03.png",
  DeclareManImage: "../assets/declare_man_img.png",
  ThumbsUpImage: "../assets/thumbs_up_img.png",
  MatchPlace: "../assets/location.png",
  Stadium: "../assets/stadium.png",
  Calender: "../assets/calender_icon",
  Clock: "../assets/clock.png",
  MobilePhone: "../assets/mobile_phone.png",
  TermsVectorImage: "../assets/vector_sector_img.png",
  handIllus: "../assets/hand_illus.png",
  paymentSettlement: "../assets/payment_settlement.png",
  paymentSettled: "../assets/payment_settled.png",
  success: "../assets/success.png",
  Coin: "../assets/coin.png",
  DiamondPackage: "../assets/diamond_package.png",
  Diamond: "../assets/diamond.png",
  Discount: "../assets/discount_02.png",
  GiftBox: "../assets/gift_box.png",
  GoldMedal: "../assets/gold_medal.png",
  Crown: "../assets/crown.png",
  GoldPackage: "../assets/gold_package.png",
  SilverMedal: "../assets/silver_medal.png",
  SilverPackage: "../assets/silver_package.png",
  SmallDiamond: "../assets/small_diamond.png",
  StandardPackage: "../assets/standard_package.png",
  CallHistory: "../assets/call_history.png",
  CallSatement: "../assets/call_Statement.png",
  MatchStatement: "../assets/match_statement.png",
  OnepageReport: "../assets/one_page_report.png",
  Upgrade: "../assets/upgrade.png",
  Settlement: "../assets/settlement.png",
  Phone: "./assets/phone.png",
  Desktop: "./assets/desktop.png",
  OffersMessage: "./assets/offers_message.png",
  CongratsImage: "./assets/congrats_image.png",
  PDFBooking: "./assets/pdf_booking.png",
  SuitcaseImage: "./assets/suitcase_image.png",
  AirPlaneBG: "./assets/airplane_bg.png",
  CricketBanner: "./assets/bat_ball.png",
  EmojiBanner: "./assets/emojis.png",
  FootBallBanner: "./assets/football_leg.png",
  CasinoBanner: "./assets/casino_banner.png",
  EntBanner: "./assets/man_suitcase.png",
  DiscountIcon: "./assets/discount_icon.png",
  TourGiftBox: "./assets/tour_gift_box.png",
  DogImage: "./assets/dog.jpg",
  PrivacyPolicyImg: "./assets/privacy-policy-img.png",
  CallingProfileImage: "./assets/calling_profile_image.png",
  CSKImage: "./assets/CSKImage.png",
  MIImage: "./assets/mumbaiIndiansImg.png",
  CricketBat: "./assets/cricketBat.png",
  CricketBall: "./assets/cricketBall.png",
  edit_profile_icon: "../assets/edit_profile_icon.png",
  Call_Waiting: "../assets/Calling-waiting.png",
  Chat_Profile: "../assets/calling_profile_image.png",
  We_Logo: "../assets/We-Logo.png",
};
export const ImageBaseUrl =
  "https://we2-call-images.s3.us-east-2.amazonaws.com";

export const ImageBaseUrl2 =
  "https://we2-call-images-singapore.s3.ap-southeast-1.amazonaws.com";

export const UpdatedBaseUrl =
  "https://we2-call-images.s3.us-east-2.amazonaws.com";
