import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homepage/HomePage";
import CustomHeader from "./components/header/Header";
import Meetings from "./pages/meetings/Meetings";
import Join from "./GroupCall/join";
import ChatButton from "./components/footer/ChatButton";
import ToursAndTournaments from "./pages/tours-and-tournaments/ToursAndTournaments";
import ViewOffers from "./pages/tours-and-tournaments/ViewOffers";
import "./styles/common.css";
import "./App.css";
import { useEffect, useState } from "react";
import GroupCall from "./GroupCall";
import GroupCall1 from "../src/GroupCall/old_index"
import ChatPage from "./pages/chat/ChatPage";

const App = () => {
  const [chatPath, setChatPath] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://we2call.com";
    }
  }, [isMobile]);

  useEffect(() => {
    if (window.location.pathname === "/chat") {
      setChatPath(true);
    }
  }, [window.location.pathname]);
  const [showChatPage, setShowChatPage] = useState(false);

  return (
    <div className="we-2-call-main-homepage">
      <BrowserRouter>
        <CustomHeader setShowChatPage={setShowChatPage} />
        <div className="main-container">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/meetings" element={<Meetings />} />
            <Route path="/Join/:id" element={<Join />} />
            <Route path="/meetings/:id" element={<Meetings />} />
            <Route path="/offers" element={<ViewOffers />} />
            <Route
              path="/tours-and-tournaments"
              element={<ToursAndTournaments />}
            />
            <Route path="/group-call/:id" element={<GroupCall />} />
            <Route path="/group-call1/:id" element={<GroupCall1 />} />
          </Routes>
        </div>
        {chatPath === true ? null : (
          <ChatButton setShowChatPage={setShowChatPage} />
        )}
        <ChatPage
          showChatPage={showChatPage}
          setShowChatPage={setShowChatPage}
        />
      </BrowserRouter>
    </div>
  );
};

export default App;
