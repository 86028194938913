import { useState } from "react";
import ScoreBoard from "./ScoreBoard";
import LiveStreaming from "./LiveStreaming";
import Line from "./Line";
import MatchPosition from "./MatchPosition";
import "./styles.css";
import moment from "moment";
import CallingChat from "./CallingChat";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { Images } from "../images";
import Select from "react-select";


const professionalUser = [
    { value: 4, label: "Match Position" },  
    { value: 1, label: "Score Board" },  
    { value: 2, label: "Live Streaming" },  
    { value: 3, label: "Line" },  
] 

function Calling(props) {
  const { isAdminCreated, meetingId, liveMeeting, messageList,setMessageList,sendMessage } = props;
  const index = liveMeeting.meeting_type === "Personal" && isAdminCreated ? 0 : 5
  const [activeIndex, setActiveIndex] = useState(index);
  const values = liveMeeting.meeting_type != "Personal" ? professionalUser : null;
   const buttons = values;
 
  const [minSidebar, setMinSidebar] = useState(true);
  const [maxSidebar, setMaxSidebar] = useState(false);
  const handleOpenMaxSidebar = () => {
    setMaxSidebar(true);
    setMinSidebar(false);
  };
  const handleOpenMinSidebar = () => {
    setMinSidebar(true);
    setMaxSidebar(false);
  };


  return (
    <div className="d-flex">
      
      {minSidebar && (
        <div className="header-bg h-80vh rounded-pill p-1 d-flex flex-column flex-between">
          <div className="d-flex flex-column">
            <div
              onClick={() => handleOpenMaxSidebar()}
              className="send-pic yellow-clr send-msg-bg rounded-circle flex-center cursor-pointer"
            >
              <FaChevronRight className="chat-icon" />
            </div>
            <img
              src={Images?.We_Logo}
              className="we-img mt-2 cursor-pointer"
              alt=""
            />
            <img
              src={Images?.profileIcon}
              className="we-img mt-2 cursor-pointer"
              alt=""
            />
          </div>
          <div
            className="send-pic send-msg-bg rounded-circle flex-center cursor-pointer"
          >
            <IoSettingsOutline className="chat-icon" />
          </div>
        </div>
      )}
      {maxSidebar && (
        <div className={`header-bg  rounded p-1 ${
              minSidebar ? "w-70" : "w-100"
            }`}>
          <div className="w-100 num-btn-bg rounded p-2  bgDarkside">
            <div className="d-flex align-items-center justify-content-between medium-font" style={{gap:"10px"}}>
              {liveMeeting.meeting_type === "Personal" && !isAdminCreated ? (
                <></>
              ):(
                  <Select
                    className="w-100 react-select-container cursor-pointer border-yelo rounded"
                    classNamePrefix="react-select"
                    placeholder="Select"
                    options={buttons}
                    value={buttons[activeIndex]}
                    onChange={(e) => setActiveIndex(e.value)}
                    isSearchable={true}
                  />
              )}
              
              <div style={{width:"60px"}}
              onClick={()=> setActiveIndex(5)}>
                <img src={Images.chat_icon} alt="" style={{width:"40px"}}/>
              </div>
              <div
                onClick={() => handleOpenMinSidebar()}
                className="p-1 yellow-clr minIcon rounded-circle flex-center cursor-pointer"
              >
                <FaChevronLeft className="chat-icon" />
              </div>
            </div>
            <div className="d-flex flex-wrap font-14">
              <div className="detailMeeting pxy bg-blue mt-1">
                
                {liveMeeting?.match_name && (
                    <span>{liveMeeting?.match_name}</span> 
                )}
                <span>{liveMeeting?.event_name}</span>
                <span>{moment(liveMeeting?.given_time_stamp).format("DD-MM-YYYY")} &nbsp; {moment(liveMeeting?.given_time_stamp).format("hh:mm A")}
</span> 
              </div>
            </div>
            <div className="d-flex flex-wrap font-14">
              <div className="detailMeeting py-1 bg-blue mt-1">
                
                  {activeIndex === 1 && <ScoreBoard />}
                  {activeIndex === 2 && <LiveStreaming />}
                  {activeIndex === 3 && <Line />}
                  {activeIndex === 4 && <MatchPosition />}
                  {activeIndex === 5 && <CallingChat 
                    messageList={messageList}
                    setMessageList={setMessageList}
                    sendMessage={sendMessage}
                  />}
              </div>
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
}

export default Calling;
