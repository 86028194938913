import React from "react";
import { Modal } from "antd";
import { useState } from "react";
import { Images } from "../../images";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { RESET_PASSWORD } from "../../api/endpoints";
import { call } from "../../api/axios";
import ThankyouPopup from "./ThankyouPopup";

function ResetPassword(props) {
  const { resetPasswordModal, setResetPasswordModal } = props;
  const register_id = localStorage?.getItem("register_id");
  const [showPassword, setShowPassword] = useState();
  const [showNewPassword, setShowNewPassword] = useState();
  const [showConfrmPassword, setShowConfrmPassword] = useState();
  const [resetPasswordData, setResetPasswordData] = useState({});
  const [error, setError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [openThankyouModal, setOpenThankyouModal] = useState(false);

  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleShowConfrmPassword = () => {
    setShowConfrmPassword((prev) => !prev);
  };
  const handleShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };
  const handleCancel = () => {
    setResetPasswordModal(false);
  };

  const handleResetPasswordChange = (e) => {
    setResetPasswordData({
      ...resetPasswordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResetPassword = async () => {
    if (
      !resetPasswordData?.old_password ||
      !resetPasswordData?.new_password ||
      !resetPasswordData?.confirm_password
    ) {
      setError("Please Enter Required Fields");
      return;
    }
    if (
      resetPasswordData?.new_password !== resetPasswordData?.confirm_password
    ) {
      setError("New Password And Confirm Password Should Match");
      return;
    }
    if (
      resetPasswordData?.old_password === resetPasswordData?.confirm_password &&
      resetPasswordData?.old_password === resetPasswordData?.new_password
    ) {
      setError("Old Password And New Password Should Not Be Same");
      return;
    }
    setIsProcessing(true);
    setError("");
    await call(RESET_PASSWORD, {
      register_id,
      old_password: resetPasswordData?.old_password,
      new_password: resetPasswordData?.new_password,
      confirm_password: resetPasswordData?.confirm_password,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          setError("");
          setOpenThankyouModal(true);
          setTimeout(() => {
            setOpenThankyouModal(false);
          }, 2000);
          setTimeout(() => {
            setResetPasswordModal(false);
          }, 3000);
          setIsProcessing(false);
          setResetPasswordData({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
        } else {
          setIsProcessing(false);
          setError(
            res?.data?.message ? res?.data?.message : "Something Went Wrong"
          );
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
        setError("Something Went Wrong");
      });
  };

  return (
    <Modal
      open={resetPasswordModal}
      className="login-modal"
      centered
      footer={null}
      onCancel={() => handleCancel()}
    >
      <center>
        <div className="we-2-call-heading">Reset Password</div>
        <div className="mt-10">
          <div className="mt-10">
            <label className="white-font flex font-10">
              Enter Old Password
            </label>
            <div className="flex-space-between by-id-btn p-8 font-12 mt-5">
              <img
                className="username-img"
                src={Images.LoginLockIcon}
                alt="Password_Icon"
              />
              <input
                name="old_password"
                className="login-inputs"
                type={showPassword ? "text" : "password"}
                placeholder="Enter Old Password"
                onChange={(e) => handleResetPasswordChange(e)}
              />
              <div
                className="flex font-18 mr-5 cursor-pointer"
                onClick={() => handleShowPassword()}
              >
                {showPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
              </div>
            </div>
          </div>
          <div className="mt-10">
            <label className="white-font flex font-10">
              Enter New Password
            </label>
            <div className="flex-space-between by-id-btn p-8 font-12 mt-5">
              <img
                className="username-img"
                src={Images.LoginLockIcon}
                alt="Password_Icon"
              />
              <input
                name="new_password"
                className="login-inputs"
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter New Password"
                onChange={(e) => handleResetPasswordChange(e)}
              />
              <div
                className="flex font-18 mr-5 cursor-pointer"
                onClick={() => handleShowNewPassword()}
              >
                {showNewPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
              </div>
            </div>
          </div>
          <div className="mt-10">
            <label className="white-font flex font-10">
              Confirm New Password
            </label>
            <div className="flex-space-between by-id-btn p-8 font-12 mt-5">
              <img
                className="username-img"
                src={Images.LoginLockIcon}
                alt="Password_Icon"
              />
              <input
                name="confirm_password"
                className="login-inputs"
                type={showConfrmPassword ? "text" : "password"}
                placeholder="Confirm New Password"
                onChange={(e) => handleResetPasswordChange(e)}
              />
              <div
                className="flex font-18 mr-5 cursor-pointer"
                onClick={() => handleShowConfrmPassword()}
              >
                {showConfrmPassword ? <BsEyeFill /> : <BsEyeSlashFill />}
              </div>
            </div>
          </div>
          <div
            className="login-btn mt-20 cursor-pointer"
            onClick={() => handleResetPassword()}
          >
            {isProcessing ? "Processing..." : "Reset Password"}
          </div>
        </div>
        {error && <div className="font-10 red-text fw-600 mt-10">{error}</div>}
      </center>
      <ThankyouPopup
        displayData={"Your Successfully Changed Your Password"}
        setOpenThankyouModal={setOpenThankyouModal}
        openThankyouModal={openThankyouModal}
      />
    </Modal>
  );
}

export default ResetPassword;
