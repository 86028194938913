import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { Zeego_server_url, Zeego_AppId } from "../../src/config/config_local";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Calling from "../video-call/Calling";
import "./Calldesign.css";
import { IoMicOutline, IoMicOffOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineVideocamOff, MdOutlineVideocam, MdOutlineFeaturedVideo } from "react-icons/md";
import { MdOutlineCallEnd } from "react-icons/md";
import { call } from "../../src/config/axios";

import { FaMicrophone, FaVideo, FaVideoSlash, FaMicrophoneSlash } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
import { IoIosMore } from "react-icons/io";
import { MdCallEnd, MdPerson } from "react-icons/md";
import { IonModal } from "@ionic/react";
import { IoClose } from "react-icons/io5";
import ReactDOMServer from "react-dom/server";
import { LuSendHorizonal } from "react-icons/lu";
import { openrequestJoin, sendRequestJoin, OpenRequest3rdMethod } from "../../src/utils/WebSocket";
import { GET_ALL_CLIENTS, GET_LIVE_MEETING, SIGNAL_RECORDING, UPDATE_MEETING } from "../../src/config/endpoints";
import "./styles.css";
import { Images } from "../images/index";
import Join from "./join";

const GroupCall = (props) => {
  const videoRef = useRef(null);
  const sendText = useRef(null);
  const videoRefs = useRef([]);
  const localstreamRef = useRef(null);
  const zg = useRef(null);
  const localView = useRef(null);
  const navigate = useNavigate();
  const [isJoined, setIsJoined] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [videoElements, setVideoElements] = useState([]);
  const [ParticpentRequest, setParticpentRequest] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [videoElementIds, setVideoElementIds] = useState([]);
  const [muteAudio, setMuteAudio] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [localVideoClass, setLocalVideoClass] = useState("remote-video");
  const [remoteVideoClass, setremoteVideoClass] = useState("remote-video");
  const [joinList, setJoinList] = useState([]);
  const [remoteStreamId, setRemoteStreamId] = useState(null);
  const [showWaitingList, setShowWaitingList] = useState(false);
  const [chatPopupOpen, setChatPopupOpen] = useState(true);
  const isAdminCreated = localStorage.getItem("isAdminMeeting") === "true";
  const [popupmsg, setPopupmsg] = useState("");
  const [callEndPopup, setCallEndPopup] = useState(false);
  const [liveMeeting, setLiveMeeting] = useState({});
  const [matchPosition, setMatchPosition] = useState(false);
  const [minSidebar, setMinSidebar] = useState(true);
  const [maxSidebar, setMaxSidebar] = useState(false);
  const [videoChat, setVideoChat] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const [selectedImage, setSelectedImage] = useState("");

  let userName = "Default User";
  let callType = "audio-video";

  // const requestMicrophonePermission = async () => {
  //   if (isPlatform("ios")) {
  //     try {
  //       await navigator.mediaDevices.getUserMedia({ audio: true });
  //       console.log("Microphone permission granted");
  //     } catch (err) {
  //       console.error("Microphone permission not granted", err);
  //     }
  //   }
  // };

  const onGoingMeetingData = localStorage.getItem("on_going_meeting");
  if (onGoingMeetingData) {
    const meetingObject = JSON.parse(onGoingMeetingData);
    userName = meetingObject.user_name;
    callType = meetingObject.videoType;
  }
  const [openWantToJoinPopup, setOpenWantToJoinPopup] = useState(false);
  const capitalizedUserName = userName.charAt(0).toUpperCase();
  const RoomId = window.location.pathname.split("/").slice(-1)?.[0];
  // const RoomId = props.id;
  const register_Id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
  const userID = localStorage.getItem("register_id");
  const streamID = Array(9)
    .fill()
    .map(() => ((Math.random() * 36) | 0).toString(36))
    .join("");

  useEffect(() => {
    getLiveMeetingData();
  }, []);


  const onRequestRecieve = (event) => {
    if (!event.data) {
      return;
    }
    const msg = JSON.parse(event.data);
    console.log("onRequestRecievemsg", msg);
    if (!isAdminCreated) {
      console.log("not admin");
      if (msg.from_user_id === register_Id && msg.message === "accept") {
        console.log("accept", msg);

        setWaiting(false);
        createRoom();
      } else if (msg.from_user_id === register_Id && msg.message === "reject") {
        console.log("reject", msg);
        dropCall();
      }
    }

  };

  useEffect(() => {
    const videoContainer = document.querySelector(".video-container");

    const hasVideoElements = videoElementIds.length > 0;
    console.log("hasVideoElements", hasVideoElements);
    if (videoElementIds.length < 1) {
      setLocalVideoClass("local-stream-video");
    } else if (videoElementIds.length === 1) {
      setLocalVideoClass("small-video");
      videoElementIds.forEach(function (id) {
        var element = document.getElementById(id);
        if (element) {
          element.classList.remove("medium-video");
          element.classList.add("remote-video");
        }
      });
    } else if (videoElementIds.length > 1) {
      setLocalVideoClass("medium-video");
      videoElementIds.forEach(function (id) {
        var element = document.getElementById(id);
        if (element) {
          element.classList.remove("remote-video");
          element.classList.add("medium-video");
        }
      });

      if (videoContainer) {
        const videoElements = videoContainer.querySelectorAll("video");

        videoElements.forEach((videoElement) => {
          videoElement.classList.remove("main-video");
          //videoElement.classList.add('medium-video');
        });
      }
    }
  }, [videoElementIds]);
  const addVideoElementId = (id) => {
    setVideoElementIds((prevIds) => [...prevIds, id]);
  };

  const removeVideoElementId = (idToRemove) => {
    setVideoElementIds((prevIds) => prevIds.filter((id) => id !== idToRemove));
  };
const handleOpenRequestForParticipants = async () => {
  const newItems = await OpenRequest3rdMethod(RoomId);
    
  if (newItems && newItems.length > 0) {
        const acceptedItems = newItems.filter(item => item.accepted === 1);

    if (acceptedItems.length > 0) {
      setParticpentRequest(false);
      
      if(waiting === true){
            createRoom()
        }
        setWaiting(false);
      console.log('create Room')
    } else {
      
      console.log("Waiting for accepted participants...");
    }
      
  }else{
    console.log('Drop Call')
        dropCall();
  }
};
  
  useEffect(() => {
    openrequestJoin({ onmessage: onRequestRecieve })
      .then(() => {
        console.log("success running of socket");
        if (!isAdminCreated && waiting) {
          const paramObj = {
            meeting_id: RoomId,
            register_id: register_Id,
            type: "send_request",
          };
          callTokenApi(paramObj, "send_request");
          sendRequestJoin("waiting", register_Id,"",RoomId);
          const timeoutId = setTimeout(() => {
            setParticpentRequest(true);
          }, 10000);
           return () => clearTimeout(timeoutId);
        }
      })
      .catch((error) => {
        console.error("Error opening WebSocket connection:", error);
      });
  }, []);


   useEffect(() => {
  
  if (!isAdminCreated && ParticpentRequest === true) {
    const intervalId = setInterval(() => {
      handleOpenRequestForParticipants();
    }, 5000);

    
    return () => clearInterval(intervalId);
  }
}, [isAdminCreated, ParticpentRequest]); 

  const initEvent = () => {
    console.log("initEvent is called");
    zg.current.on("roomStateUpdate", (roomID, state, errorCode, extendedData) => {
      if (state === "DISCONNECTED") {
        console.log("DISCONNECTED");
      }
      if (state === "CONNECTING") {
        console.log("CONNECTING");
      }
      if (state === "CONNECTED") {
        console.log("CONNECTED");
      }
    });
    zg.current.on("IMRecvBroadcastMessage", (roomID, chatData) => {
      console.log("IMRecvBroadcastMessage", roomID, chatData);
      let message = {
        ID: "zego" + chatData[0].fromUser.userID + chatData[0].sendTime,
        name: chatData[0].fromUser.userName,
        time: moment(chatData[0].sendTime).format("hh:mm A"),
        content: chatData[0].message,
        type: "received",
      };
      setMessageList((prevMessages) => [...prevMessages, message]);
    });

    zg.current.on("roomUserUpdate", (roomID, updateType, userList) => {
      console.log("roomUserUpdate", roomID);
      if (updateType === "ADD") {
        console.log("roomUserUpdate", userList);
      } else if (updateType == "DELETE") {
        console.log("roomUserUpdate", userList);
      }
    });
    zg.current.on("publisherStateUpdate", (result) => {
      //alert('publisherStateUpdate',result.streamID, result.state)
    });

    zg.current.on("remoteCameraStatusUpdate", (streamID, status) => {
      if (callType === "audio-video") {
        const divElement = document.getElementById(streamID);
        if (divElement) {
          let iconElement;
          let videoElement = divElement.querySelector("video");
          if (status === "MUTE") {
            iconElement = document.createElement("span");
            iconElement.className = "icon-dynamic";
            var faVideoElement = React.createElement(FaVideoSlash);
            var htmlString = ReactDOMServer.renderToString(faVideoElement);
            iconElement.innerHTML = htmlString;
            const belParameterElement = divElement.querySelector(".belParameter");
            const text = belParameterElement?.textContent?.trim();
            const capitalizedText = text.charAt(0).toUpperCase();
            videoElement.style.display = "none";
            divElement.classList.add("mute-active");
            const circleOuter = document.createElement("div");
            circleOuter.className = "circleOuter";

            const circleMuted = document.createElement("div");
            circleMuted.className = "circleMuted";

            const textCircle = document.createElement("span");
            textCircle.className = "textCircle";
            textCircle.textContent = capitalizedText;

            circleMuted.appendChild(textCircle);
            circleOuter.appendChild(circleMuted);

            divElement.appendChild(circleOuter);
          } else {
            iconElement = document.createElement("span");
            iconElement.className = "icon-dynamic";
            var faVideoElement = React.createElement(FaVideo);
            var htmlString = ReactDOMServer.renderToString(faVideoElement);
            iconElement.innerHTML = htmlString;
            videoElement.style.display = "block";
            divElement.classList.remove("mute-active");
            const circleOuter = divElement.querySelector(".circleOuter");
            if (circleOuter) {
              circleOuter.remove();
            }
          }

          const mutVidDiv = divElement.querySelector(".mut-vid");
          if (mutVidDiv) {
            mutVidDiv.innerHTML = "";
            mutVidDiv.appendChild(iconElement);
          }
        }
      }
    });
    zg.current.on("remoteMicStatusUpdate", (streamID, status) => {
      console.log("remoteMicStatusUpdate", streamID, status);
      const divElement = document.getElementById(streamID);
      if (divElement) {

        const soundRing = divElement.querySelector(".soundRing");
        let iconElement;
        if (status === "MUTE") {
          iconElement = document.createElement("span");
          iconElement.className = "icon-dynamic";
          var faVideoElement = React.createElement(FaMicrophoneSlash);
          var htmlString = ReactDOMServer.renderToString(faVideoElement);
          if (soundRing) {
              soundRing.style.display = "none"; 
            }
          iconElement.innerHTML = htmlString;
        } else {
          iconElement = document.createElement("span");
          iconElement.className = "icon-dynamic";
          var faVideoElement = React.createElement(FaMicrophone);
          if (soundRing) {
            soundRing.style.display = "block"; 
          }
          var htmlString = ReactDOMServer.renderToString(faVideoElement);
          iconElement.innerHTML = htmlString;
        }

        const mutVidDiv = divElement.querySelector(".mut-aud");
        if (mutVidDiv) {
          mutVidDiv.innerHTML = "";
          mutVidDiv.appendChild(iconElement);
        }
      }
    });
    zg.current.on("videoMuteState", (result) => {
      //alert('videoMuteState', result)
    });
    zg.current.on("playerStateUpdate", (result) => {
      var state = result["state"];
      var streamID = result["streamID"];
      var errorCode = result["errorCode"];
      var extendedData = result["extendedData"];
      // alert('player state update', state)
    });
    zg.current.on("roomStreamUpdate", async (roomID, updateType, streamList, extendedData) => {
      if (updateType == "ADD") {
        console.log("roomStreamUpdate", streamList);
        streamList = streamList.filter((stream) => stream.user.userID !== userID);
        if (callType === "hide") {
            streamList = streamList.filter((stream) => stream.user.userID === register_Id);
          
        }
        const newvideoElement = streamList.map((stream, index) => {
            const newRef = React.createRef();
            addVideoElementId(stream.streamID);
            (async () => {
              try {
                const remoteStream = await zg.current.startPlayingStream(
                  stream.streamID
                );
                const data = {
                  roomID: roomID,
                  updateType: updateType,
                  streamId: stream.streamID,
                  stream: remoteStream,
                  user: stream.user,
                  userID: stream.user.userID,
                  userName: stream.user.userName,
                  micMute: false,
                  camMute: false,
                };
                setUserList((prevUsers) => [...prevUsers, data]);

                let className =
                  videoElementIds.length < 1
                    ? "remote-video-1"
                    : "medium-video";

                // setremoteVideoClass(className);
                const videoElement = newRef.current;
                if (videoElement && remoteStream) {
                  videoElement.srcObject = remoteStream;
                  videoElement.muted = false;
                  videoElement.className = "main-video";
                }
                props.userJoin(userList);
              } catch (error) {
                console.error("Error starting to play the stream:", error);
              }
            })();
            return (
                // <>
                // {videoElementIds.length > 0 ? 
                <>
                {console.log(userList,'userListremotee')}
                <div id={stream.streamID} className={userList.length > 2 ? "fourUsers" : "twousers"}>
                <span className="belParameter">
                  {" "}
                  <MdPerson /> {stream.user.userName}
                </span>
                {callType !== "audio-video" && (
                  <div className="circleOuter">
                    <div className="circleMuted">
                      <div className="soundRing"></div>
                      <span className="textCircle">
                        {stream.user.userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                )}
                <div id={stream.user.id} className="audVidMute">
                  <div className={`mutAud`}>
                    <FaMicrophone />
                  </div>

                  {callType === "audio-video" && (
                    <div className={`mutVid`}>
                      <FaVideo />
                    </div>
                  )}
                </div>
                {callType === "audio-video" ? (
                  <video key={index} ref={newRef} autoPlay playsInline />
                ) : (
                  <audio key={index} ref={newRef} autoPlay />
                )}
                </div>
                
               

              </>
            );
          });
          setVideoElements((prevElements) => [
            ...prevElements,
            ...newvideoElement,
          ]);
      } else if (updateType == "DELETE") {
        streamList.map(async (stream, index) => {
          await zg.current.stopPlayingStream(stream.streamID);
          setUserList((prevUsers) => prevUsers.filter((user) => user.streamId !== stream.streamID));
          props.userJoin(userList);
          const videoElement = document.getElementById(stream.streamID);
          if (videoElement) {
            videoElement.parentNode.removeChild(videoElement);
          }
          removeVideoElementId(stream.streamID);
        });
      }
    });
    zg.current.on("roomStateChanged", async (roomID, reason, streamList, extendedData) => {
      console.log("roomStateChanged", reason);
      if (reason == "LOGINING") {
      } else if (reason == "LOGINED") {
      } else if (reason == "LOGIN_FAILED") {
      } else if (reason == "RECONNECTING") {
        // Reconnecting.
      } else if (reason == "RECONNECTED") {
        // Reconnection successful.
      } else if (reason == "RECONNECT_FAILED") {
        // Reconnection failed.
      } else if (reason == "KICKOUT") {
        // Kicked out of the room.
        // toast.error("You have been kicked out of the room");
        await zg.current.logoutRoom(RoomId);
        await zg.current.stopPublishingStream(localStream);
        await zg.current.destroyStream(localstreamRef.current);
        localStorage.removeItem("on_going_meeting");
        setIsJoined(false);
        navigate("/meetings/upcoming");
        window.location.reload();
      } else if (reason == "LOGOUT") {
        // Logout successful.
      } else if (reason == "LOGOUT_FAILED") {
        // Logout failed.
      }
    });
    zg.current.on("soundLevelUpdate", (streamList) => {
      console.log("soundLevelUpdate", streamList);
      streamList.forEach((stream) => {
        if (stream.type === "push" && !muteAudio) {
          var soundLevelElement = document.getElementById("local-circle");
          if (soundLevelElement) {
            var soundLevel = Math.round(stream.soundLevel);
            var boxShadowSize = soundLevel * 10 + "px";
            soundLevelElement.style.boxShadow = `0px 0px ${boxShadowSize} 0px #1475e3`;
            setTimeout(function () {
              soundLevelElement.style.boxShadow = "none";
            }, 2000);
          }

          var streamDiv = document.getElementById("local-video");

          if (streamDiv && stream.soundLevel > 0) {
            streamDiv.style.border = "3px solid #1475e3";
            streamDiv.style.boxShadow = "0px 0px 10px 0px blue";
            setTimeout(function () {
              streamDiv.style.boxShadow = "none";
              streamDiv.style.border = "none";
            }, 3000);
          }
        }
        if (stream.type === "pull") {
          var soundLevelElement = document.getElementById(stream.streamID);
          if (soundLevelElement) {
            var circleMutedElement = soundLevelElement.querySelector(".circle-muted");
            if (circleMutedElement) {
              var soundLevel = Math.round(stream.soundLevel);
              var boxShadowSize = soundLevel * 10 + "px";
              circleMutedElement.style.boxShadow = `0px 0px ${boxShadowSize} 0px #1475e3`;
              setTimeout(function () {
                circleMutedElement.style.boxShadow = "none";
              }, 2000);
            }
          }
          var streamDiv = document.getElementById(stream.streamID);

          if (streamDiv && stream.soundLevel > 0) {
            streamDiv.style.border = "3px solid  #1475e3";
            streamDiv.style.boxShadow = "0px 0px 10px 0px #1475e3";
            setTimeout(function () {
              streamDiv.style.boxShadow = "none";
              streamDiv.style.border = "none";
            }, 2000);
          }
        }
      });
    });
  };
  useEffect(() => {
    if (props.mute) {
      console.log("muteVideo", props.mute);
      muteRemoteCam(props.mute.streamId, props.mute.mute);
    }
  }, [props.mute]);

  const muteRemoteCam = async (streamID, mute) => {
    try {
      await zg.current.mutePlayStreamVideo(streamID, mute);
    } catch (error) {
      console.error("Error mute remote stream:", error);
    }
  };

  const callTokenApi = (paramsObj, flag) => {
    if (isAdminCreated) {
      paramsObj["userType"] = "admin";
    } else {
      paramsObj["userType"] = "user";
      paramsObj["userId"] = localStorage.getItem("register_id");
    }
    return call(SIGNAL_RECORDING, paramsObj)
      .then((res) => {
        console.log(res, "api response");
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };

  const getLiveMeetingData = async () => {
    const creator_id = isAdminCreated ? localStorage.getItem("register_id") : localStorage.getItem("creator_id");
    console.log("RoomId", RoomId);
    return await call(GET_LIVE_MEETING, {
      creator_id: creator_id,
      meeting_id: RoomId,
    })
      .then(async (res) => {
        const data = res.data?.data?.Items?.[0];
        console.log("live", data);
        setLiveMeeting(data);
        // await this.init(data);
      })
      .catch((err) => {
        throw err;
      });
  };

  const createRoom = async () => {
    console.log("createRoom is called");
    console.log("check build");
    try {
      let data = "";

      const App_Id = Zeego_AppId;
      const Server_url = Zeego_server_url;
      zg.current = new ZegoExpressEngine(App_Id, Server_url);
      initEvent();
      const paramObj = {
        meeting_id: RoomId,
        register_id: register_Id,
        type: "generate_token",
      };
      data = await callTokenApi(paramObj, "generate_token");
      // if (
      //   !AndroidPermissions.hasPermission(AndroidPermissions.PERMISSION.CAMERA)
      // ) {
      //   await AndroidPermissions.requestPermission(
      //     AndroidPermissions.PERMISSION.CAMERA
      //   );
      // }
      // if (
      //   !AndroidPermissions.hasPermission(
      //     AndroidPermissions.PERMISSION.RECORD_AUDIO
      //   )
      // ) {
      //   await AndroidPermissions.requestPermission(
      //     AndroidPermissions.PERMISSION.RECORD_AUDIO
      //   );
      // }

      // requestMicrophonePermission();

      // if (
      //   !AndroidPermissions.hasPermission(
      //     AndroidPermissions.PERMISSION.RECORD_AUDIO
      //   )
      // ) {
      //   await AndroidPermissions.requestPermission(
      //     AndroidPermissions.PERMISSION.RECORD_AUDIO
      //   );
      // }

      // requestMicrophonePermission();
      console.log("data", data);
      console.log("userID:", userID);
      console.log("userName:", userName);
      // const result = await zg.current.loginRoom(
      //   "meeting-20240605161055526",
      //   "04AAAAAGZg2D8AEHIya2MzNXV0NXhuazRrZzMA4EY0I1XZdz8SdGGP0j5vsrsaxrMc0rAWj/E1WQAvd2BODqVYW9s9dTaD/ypp1AD2BFiAsADtKBJMkwnCWC80NNAbiqr4k4ufmJXZi48aMM5Pcf/vJZNlx6AEtB+GllvQnqzPs8b0VkKaqPF2x5xjUx04Q1r0T7PPFQTa9n5ggNnmTE1BIzmqjJ2uIvH0jThmQyWlEHjZe+P1WfQwq1Mx8TmGy/22BfGyfg0DJ2LFWXIM14uzONgI+aLUkE8WJypNaX1SbkYqw/Ph7Xq9L2J1rcH3UpHpnjRi29ssxskb8B6q",
      //   // userID: client-20240605160348310
      //   { userID, userName },
      //   { userUpdate: true }
      // );
      const result = await zg.current.loginRoom(RoomId, data.token, { userID, userName }, { userUpdate: true });
      // const result = await zg.current.loginRoom(
      //   "meeting-20240605161055526",
      //   "04AAAAAGZg2D8AEHIya2MzNXV0NXhuazRrZzMA4EY0I1XZdz8SdGGP0j5vsrsaxrMc0rAWj/E1WQAvd2BODqVYW9s9dTaD/ypp1AD2BFiAsADtKBJMkwnCWC80NNAbiqr4k4ufmJXZi48aMM5Pcf/vJZNlx6AEtB+GllvQnqzPs8b0VkKaqPF2x5xjUx04Q1r0T7PPFQTa9n5ggNnmTE1BIzmqjJ2uIvH0jThmQyWlEHjZe+P1WfQwq1Mx8TmGy/22BfGyfg0DJ2LFWXIM14uzONgI+aLUkE8WJypNaX1SbkYqw/Ph7Xq9L2J1rcH3UpHpnjRi29ssxskb8B6q",
      //   { userID: "reg-20240305123058870", userName: "test1" },
      //   { userUpdate: true }
      // );

      console.log("result...........:", result);
      const deviceInfo = await zg.current.enumDevices();
      console.log("deviceInfo", deviceInfo);
      const recordParamsObj = {
        meeting_id: RoomId,
        register_id: localStorage.getItem("register_id"),
        type: "startRecord",
      };

      console.log("localstreamRef", localstreamRef);
      if (!localstreamRef.current) {
        setLocalStream(streamID);
        // let callType = "hide";

        if (callType === "audio-video") {
          localstreamRef.current = await zg.current.createZegoStream({
            camera: {
              audioInput: deviceInfo.microphones[0].deviceID,
              videoInput: deviceInfo.cameras[0].deviceID,
              video: true,
              audio: true,
            },
          });
          localstreamRef.current.playVideo(document.querySelector("#local-video"));
        } else {
          console.log("zg", zg.current);
          localstreamRef.current = await zg.current.createStream({
            camera: { audio: true, video: false },
          });
          //localstreamRef.current.playAudio();
        }

        zg.current.startPublishingStream(streamID, localstreamRef.current);
        zg.current.setSoundLevelDelegate(true, 1000);

        //localstreamRef.current.stopCaptureVideo();
        //  const videoElement = videoRef.current;
        // if (videoElement && localstreamRef.current) {
        //     videoElement.srcObject = localstreamRef.current;
        //     videoRef.current.muted = false;
        // }
        // localView.current = zg.current.createLocalStreamView(local);
        // console.log('local-view', localView.current);

        // localView.current.playVideo("local-video", {
        //     enableAutoplayDialog: true,
        // });
      }

      setRemoteStreamId(streamID);
    } catch (error) {
      const stopRecordParamsObj = {
        meeting_id: RoomId,
        type: "stopRecord",
        register_id: localStorage.getItem("register_id"),
        //remainingTime: remainingMinutes > 0 ? time : "0:0",
      };
      console.error("Error creating room:", error);
    }
  };
  const sendMessage = async (text) => {
    
    if (text) {
      try {
        const isSent = await zg.current.sendBroadcastMessage(RoomId, text);
        sendText.current.value = "";
        const message = {
          ID: "zego" + userID + Date.now(),
          name: userName,
          time: moment().format("hh:mm A"),
          content: text,
          type: "sent",
        };
        setMessageList((prevMessages) => [...prevMessages, message]);
        console.log(">>> sendMsg success,", isSent);
      } catch (error) {
        console.log(">>> sendMsg, error:", error);
      }
    }
  };
 

  const closeWaiting = () => {
    setShowWaitingList(false);
  };

  const startPlayingStream = async (streamID) => {
    try {
      const remoteStream = await zg.current.startPlayingStream(streamID);
      console.log(remoteStream, "remoteStream");
      const videoElement = document.createElement("video");
      videoElement.autoPlay = true;
      videoElement.playsInline = true;
      videoElement.muted = false;
      videoElement.srcObject = remoteStream;
      document.getElementById("remote-video-container").appendChild(videoElement);
    } catch (error) {
      console.error("Error starting to play the stream:", error);
    }
  };

  const stopPlayingStream = async (streamID) => {
    try {
      await zg.current.stopPlayingStream(streamID);
    } catch (error) {
      console.error("Error stopping to play the stream:", error);
    }
  };

  const dropCall = async () => {
    console.log("dropCall");
    try {
      await zg.current.logoutRoom(RoomId);
      await zg.current.stopPublishingStream(localStream);
      await zg.current.destroyStream(localstreamRef.current);
      localStorage.removeItem("on_going_meeting");
      setIsJoined(false);
      navigate("/meetings/upcoming");
        const paramObj = {
          meeting_id: RoomId,
          register_id: register_Id,
          type: "remove_request",
          userType: "user",
          userId: userID,
        };
        callTokenApi(paramObj, "remove_request");
      
      window.location.reload();
    } catch (error) {
      console.error("Error dropping the call:", error);
      localStorage.removeItem("on_going_meeting");
      setIsJoined(false);
      navigate("/meetings/upcoming");
      window.location.reload();
    }
  };

  const openChat = () => {
    var footer = document.getElementById("callFooter");
    var videoGroup = document.getElementById("videoGroup");
    var chatBox = document.getElementById("chatBox");
    var chat = document.getElementById("chat-btn");
    if (chatBox.style.display === "block") {
      chatBox.style.display = "none";
      chat.classList.remove("active-chat");
      setChatPopupOpen(false);
    } else {
      chatBox.style.display = "block";
      chat.classList.add("active-chat");
      setChatPopupOpen(true);
    }
    footer.style.height = chatBox.style.display === "block" ? "13vh" : "13vh";
    videoGroup.style.height = chatBox.style.display === "block" ? "70vh" : "70vh";
  };
  const muteMicro = () => {
    if (localStream) {
         const lsound = document.getElementById('localSound');
         if(lsound){}
      lsound.style.display = muteAudio ? "block" : "none";
      if (callType === "audio-video") {
        zg.current.muteMicrophone(!muteAudio);
        setMuteAudio(!muteAudio);
      } else {
        zg.current.muteMicrophone(!muteAudio);

        setMuteAudio(!muteAudio);
      }
    }
  };
  const muteCam = async () => {
    if (localstreamRef.current) {
      const deviceInfo = await zg.current.enumDevices();

      try {
        if (muteVideo) {
          zg.current.mutePublishStreamVideo(localstreamRef.current, false);
        } else {
          zg.current.mutePublishStreamVideo(localstreamRef.current, true);
        }
      } catch (error) {
        console.error("Error unmute video:", error);
      }

      setMuteVideo(!muteVideo);
    }
  };


  const changeClasses = () => {
    if (videoElementIds.length === 1) {
      let localVideoClassvar = localVideoClass === "small-video" ? "remote-video" : "small-video";
      setLocalVideoClass(localVideoClassvar);
      videoElementIds.forEach(function (id) {
        var element = document.getElementById(id);
        if (element) {
          element.classList.remove("medium-video");
          element.classList.remove(localVideoClassvar === "remote-video" ? "remote-video" : "small-video-remote");
          element.classList.add(localVideoClassvar === "remote-video" ? "small-video-remote" : "remote-video");
        }
      });
    }
  };

  const toggleVideo = () => {
    setIsVideoOn((prevState) => !prevState);
  };

  const handleMatchPositionOpen = () => {
    setMatchPosition(true);
    // setVideoChat(false);
    setChatPopupOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        console.log("handleFileChange", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };
  const firstFourUsers =  videoElements.length > 12 ? videoElements.slice(0, 2) : videoElements.slice(0, 4) ;
  const remainingUsers =  videoElements.length > 12 ? videoElements.slice(2) : videoElements.slice(4); 

  return (
    <>
      <div className="room-page d-flex">
        <div id="videoGroup" className="video-group h-80vh mx-2 ">
          <Calling 
          meetingId={RoomId}
          isAdminCreated={isAdminCreated}
          liveMeeting={liveMeeting}
          messageList={messageList}
          setMessageList={setMessageList}
          sendMessage={sendMessage}
          />
          <div 
          className={`localContainer ${
              minSidebar ? "w-100" : "w-70"
            }`}
          >
            {waiting && !isAdminCreated ? (
              <>
              <div className="waitScreen">
                  <img className="" src={Images?.Call_Waiting} alt="" style={{height:"40vh",width:"200px"}}/>
                  <h3 style={{textAlign:"center"}}>Waiting for Joining..</h3>
                </div>
              </>
            ) : (
                <>
              <div className={videoElements.length > 4 ? 'ContainerMoreThenFour' : videoElements.length > 1 ?  'ContainerMoreThenTwo' : 'myVideo'}>
                  <div
                  id="local-video"
                  className={`${videoElements.length > 4 ? 'localMorethenfourUsers' : videoElements.length > 1 ? 'localThreeUsers' :  'remoteVideo'} ${
                    muteVideo ? "mute-active" : "mute-inactive"
                  } ${callType != "audio-video" ? "audio-active" : ""} `}
                >
                  <span className="yelParameter">
                    {" "}
                    <MdPerson /> {userName} (you)
                  </span>
                  {muteVideo || callType != "audio-video" ? (
                    <div className="circleOuter">
                      <div id="local-circle" className="circleMuted">
                        <div id="localSound" className="soundRing"></div>
                        <span className="textCircle">
                          {capitalizedUserName}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="callControls">
                      <span
                        onClick={() => muteMicro()}
                        className="light-blue-bg br5 p-1 cspan flex-center font2rem  cursor-pointer"
                        style={{width:"3rem",height:"3rem"}}
                      >
                        {muteAudio ? (
                          <IoMicOffOutline className="font2rem" />
                        ) : (
                          <IoMicOutline className="font2rem" />
                        )}
                      </span>

                      {callType === "audio-video" ? (
                              <span
                        onClick={() => muteCam()}
                        className="light-blue-bg br5 p-1  cspan flex-center font2rem  cursor-pointer"
                        style={{width:"3rem",height:"3rem"}}
                      >
                        {isVideoOn ? (
                          <MdOutlineVideocam className="font2rem" />
                        ) : (
                          <MdOutlineVideocamOff className="font2rem" />
                        )}
                      </span>
                            ) : (
                              <></>
                            )}
                      <span
                        className="red-bg br-5 p-1 flex-center font2rem cspan  cursor-pointer"
                        style={{width:"4rem",height:"3rem"}}
                        onClick={() => setCallEndPopup(true)}
                      >
                        <MdOutlineCallEnd className="font2rem" />
                      </span>
                    </div>

                          </div>
                          {videoElements.length > 1 ? (
              <>
                <div className={  videoElements.length > 12  ? "sideCallDetailView"  : "sideCallView"}>
                  {firstFourUsers}
                </div>
                {videoElements.length < 10 ? (
                <div className="bottomCallView">
                  {remainingUsers}
                </div>
                ) : videoElements.length <= 12 ? (
                  <div className="bottomDetailedCallView">
                  {remainingUsers}
                </div>
                ):(
                  <div className="bottomDetailBigCallView">
                  {remainingUsers}
                </div>

                )}  
              </>
             ) : (<>
                {videoElements}
             </>)}
                          
                        </div>
                      </>
            )}
            
            {callEndPopup && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <div className="font-25">Are you sure you want end the call?</div>
                  <div className="font-18 p-8 br-5 green-bg-clr mt-2rem">
                    Re-join after
                    <br /> some time...
                  </div>
                  <div className="font-18 flex-space-between mt-20">
                    <button className="w-48 p-8 br5" style={{ backgroundColor: "#fff" }} onClick={() => setCallEndPopup(false)}>
                      Cancel
                    </button>
                    <button className="w-48 p-8 br5" onClick={() => dropCall()} style={{ backgroundColor: "#fc3738" }}>
                      End Call
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          
        </div>
        
      </div>
     
    </>
  );
};

export default GroupCall;
