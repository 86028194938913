import { Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { Images } from "../../images";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";

function SigninModal(props) {
  const { signinModal, setSigninModal } = props;
  const [showPassword, setShowPassword] = useState(false);
  const VerifySteps = ["Sign Up", "By Phone", "By Email"];
  const [activeHead, setActiveHead] = useState(0);
  const cricketPlayingCountries = [
    "India",
    "Australia",
    "England",
    "Pakistan",
    "South Africa",
    "West Indies",
    "Sri Lanka",
    "New Zealand",
    "Bangladesh",
    "Afghanistan",
    "Ireland",
    "Zimbabwe",
    "Scotland",
    "Netherlands",
    "United Arab Emirates",
    "Nepal",
    "Oman",
    "Papua New Guinea",
    "Namibia",
    "Canada",
  ];
  const handleCancel = () => {
    setSigninModal(false);
    setActiveHead(0);
  };
  const handleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleVerify = (active) => {
    active === 0
      ? setActiveHead(1)
      : active === 1
      ? setActiveHead(2)
      : handleCancel();
  };

  return (
    <Modal
      className="login-modal"
      centered
      open={signinModal}
      footer={null}
      onCancel={() => handleCancel()}
    >
      <center>
        <div className="font-14 fw-600">Signup</div>
        <div className="font-8">Create Your Account</div>
        <Row className="flex-space-between mt-5">
          {VerifySteps.map((item, index) => {
            return (
              <Col key={index} span={7}>
                <div
                  className={`by-id-btn p-5 font-12 ${
                    activeHead === index ? "yellow-border" : null
                  }`}
                >
                  {item}
                </div>
              </Col>
            );
          })}
        </Row>
        {activeHead === 0 && (
          <form>
            <div className="mt-10">
              <label className="flex font-10">Meeting Head Id</label>
              <div className="flex by-id-btn p-5 font-10 mt-5">
                <img
                  className="username-img"
                  src={Images.LoginUserIcon}
                  alt="User_Icon"
                />
                <input
                  className="login-inputs ml-5"
                  type="email"
                  placeholder="Enter Meeting Head ID"
                />
              </div>
            </div>
            <div className="mt-10">
              <label className="flex font-10">Select Country</label>
              <div className="flex by-id-btn p-5 font-10 mt-5">
                <img
                  className="username-img"
                  src={Images.LoginUserIcon}
                  alt="User_Icon"
                />
                <select className="w-90 country-select">
                  {cricketPlayingCountries.map((item, index) => {
                    return <option key={index}>{item}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="mt-10">
              <label className="flex font-10">Enter User Name</label>
              <div className="flex by-id-btn p-5 font-10 mt-5">
                <img
                  className="username-img"
                  src={Images.LoginUserIcon}
                  alt="User_Icon"
                />
                <input
                  className="login-inputs ml-5"
                  type="email"
                  placeholder="Enter User Name"
                />
              </div>
            </div>
            <div className="mt-10">
              <label className="flex font-10">Password</label>
              <div className="flex-space-between by-id-btn p-5 font-10 mt-5">
                <img
                  className="username-img"
                  src={Images.LoginLockIcon}
                  alt="Password_Icon"
                />
                <input
                  className="login-inputs ml-5"
                  type="password"
                  placeholder="Enter Your Password"
                />

                <div
                  className="flex font-18 mr-5"
                  onClick={() => handleShowPassword()}
                >
                  {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                </div>
              </div>
            </div>
            <div className="mt-10">
              <label className="flex font-10">Re-Enter Password</label>
              <div className="flex-space-between by-id-btn p-5 font-10 mt-5">
                <img
                  className="username-img"
                  src={Images.LoginLockIcon}
                  alt="Password_Icon"
                />
                <input
                  className="login-inputs ml-5"
                  type="password"
                  placeholder="Enter Your Password"
                />

                <div
                  className="flex font-18 mr-5"
                  onClick={() => handleShowPassword()}
                >
                  {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
                </div>
              </div>
            </div>
          </form>
        )}
        {activeHead === 1 && (
          <div className="mt-10">
            <label className="flex font-10">Enter Phone Number</label>
            <div className="flex by-id-btn p-5 font-10 mt-5">
              <select className="country-select">
                <option>+91</option>
              </select>
              <input
                className="login-inputs ml-5"
                type="email"
                placeholder="Enter Phone Number"
              />
            </div>
          </div>
        )}
        {activeHead === 2 && (
          <div className="mt-10">
            <label className="flex font-10">Enter Email</label>
            <div className="flex by-id-btn p-5 font-10 mt-5">
              <img
                className="username-img"
                src={Images.LoginUserIcon}
                alt="User_Icon"
              />
              <input
                className="login-inputs ml-5"
                type="email"
                placeholder="Enter Email"
              />
            </div>
          </div>
        )}

        <div
          className="login-btn mt-20"
          onClick={() => handleVerify(activeHead)}
        >
          Verify
        </div>
      </center>
    </Modal>
  );
}

export default SigninModal;
