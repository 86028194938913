import React from "react";
import { Layout } from "antd";
import MainContent from "../../components/content/MainContent";
import CustomFooter from "../../components/footer/Footer";
import "./style.css";
const { Content } = Layout;

const Homepage = () => {
  return (
    <Layout className="we-2-call-homepage">
      <Content>
        <MainContent />
      </Content>
      <CustomFooter />
    </Layout>
  );
};

export default Homepage;
