import { FaPhotoFilm } from "react-icons/fa6";
import { VscSend } from "react-icons/vsc";
import { useState } from "react";
import moment from "moment";
import Select from "react-select";

function LiveStreaming() {


  const [selectedMatch, setSelectedMatch] = useState("");



   const matches = [
    { id: 1, value: "Join Users", label: "Match 1", videoUrl: "https://www.example.com/match1" },
    { id: 2, value: "Join Users", label: "Match 2", videoUrl: "https://www.example.com/match2" },
    { id: 3, value: "Join Users", label: "Match 3", videoUrl: "https://www.example.com/match3" },
  ];

  const handleMatchSelect = (e) => {
    
  };
  return (
    <div>
       <div className="dropdown-container">
             <Select
              className="w-100 react-select-container cursor-pointer border-yelo rounded"
              classNamePrefix="react-select"
              placeholder="Select Match"
              options={matches}
              value={selectedMatch}
              onChange={(e) => handleMatchSelect(e)}
              isSearchable={true}
            />
      </div>

      <div className="live-container">
      <iframe
        className="video-player"
        width="100%"
        height="200px"
        src="https://www.youtube.com/embed/cZV5FUCLc6g"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Video"
      ></iframe>
    </div>

      {/* {selectedMatch ? (
        <div className="video-container">
          <iframe
            className="video-iframe"
            width="560"
            height="315"
            src={selectedMatch}
            title="Match Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ):(
        <div className="video-container">
          <iframe
            className="video-iframe"
            width="560"
            height="315"
            src={selectedMatch}
            title="Match Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )} */}
    </div>
  );
}

export default LiveStreaming;
