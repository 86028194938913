import React from "react";
import { Button, Card, Col, Row, Typography } from "antd";
import { Images } from "../../images";
import { useNavigate } from "react-router-dom";

const CallManagement = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Typography.Title level={3} className="we-2-call-homepage-sub-heading">
        {title}
        <div className="hr-yellow-line"></div>
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={24} className="we-2-left-col">
          <Card className="br-20 we-2-call-right-card" style={{ height: "100%" }}>
            <div className="we-2-call-right-card-content-container">
              <div className="w-50">
                <h1 className="we-2-call-men-heading mb-0">We2Call</h1>
                <p className="support-call-text mt-0">For Support Call</p>
                <Button
                  className="flex-center we-2-call-button custom-we2-call-men-btn"
                  onClick={() => navigate("/meetings/")}
                >
                  We2Call
                </Button>
              </div>
              <div className="we-2-call-men-image">
                <img src={Images.we2call_men_banner} alt="we-2-call-men" />
              </div>
            </div>
            <img
              src={Images.star_icon}
              alt="star-icon"
              className="star-icon star-icon-one-position"
            />
            <img
              src={Images.star_icon}
              alt="star-icon"
              className="star-icon star-icon-two-position"
            />
            <img
              src={Images.star_icon}
              alt="star-icon"
              className="star-icon star-icon-three-position"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CallManagement;
