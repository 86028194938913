import React from "react";
import { Col, Row } from "antd";
import moment from "moment";
function CompletedMeetings({ meetingsCompleted }) {
  return (
    <div className="common-container">
      <div className="upcoming-meetings-heading">
        <Row>
          <Col span={3}>
            <div className="font-12 fw-600">CREATED BY</div>
          </Col>
          <Col span={3}>
            <div className="font-12 fw-600">DATE</div>
          </Col>
          <Col span={3}>
            <div className="font-12 fw-600">TIME</div>
          </Col>
          <Col span={8}>
            <div className="font-12 fw-600">MEETING TITLE</div>
          </Col>
          <Col span={4}>
            <div className="font-12 fw-600">MEETING TYPE</div>
          </Col>
          <Col span={3}>
            <div className="font-12 fw-600">STATUS</div>
          </Col>
        </Row>
      </div>
      <div className="meeting-content">
        {meetingsCompleted?.map((item, index) => {
          return (
            <div className="upcoming-meetings-content" key={index}>
              <Row>
                <Col span={3}>
                  <div className="font-12 fw-600">{item?.createdBy}</div>
                </Col>
                <Col span={3}>
                  <div className="font-12 fw-600">
                    {moment(item?.date).format("DD-MM-YYYY")}
                  </div>
                </Col>
                <Col span={3}>
                  <div className="font-12 fw-600">{item?.time}</div>
                </Col>
                <Col span={8}>
                  <div className="font-12 fw-600">
                    {item?.event_name} - {item?.match_name}
                  </div>
                </Col>
                <Col span={4}>
                  <div className="font-12 fw-600">{item?.meeting_type}</div>
                </Col>
                <Col span={3} className="flex-center">
                  <div className="complete-meeting-btn font-12 fw-600">Completed</div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CompletedMeetings;
