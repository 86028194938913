import React from "react";
import { Card, Col, Row } from "antd";
import { Footer } from "antd/es/layout/layout";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiOutlineInstagram,
  AiFillLinkedin,
} from "react-icons/ai";
import { MdLockOutline } from "react-icons/md";
import { PiTelegramLogo } from "react-icons/pi";
import { Link } from "react-router-dom";
import { Images } from "../../images";

const CustomFooter = () => {
  const socialMediaData = [
    {
      icon: <AiFillFacebook />,
      link: "/facebook",
      bgColor: "facebook-bg-gradient",
    },
    {
      icon: <AiOutlineTwitter />,
      link: "/twitter",
      bgColor: "twitter-bg-gradient",
    },
    {
      icon: <AiOutlineInstagram />,
      link: "/instagram",
      bgColor: "instagram-bg-gradient",
    },
    {
      icon: <AiFillLinkedin />,
      link: "/linkedin",
      bgColor: "linkedin-bg-gradient",
    },
    {
      icon: <PiTelegramLogo />,
      link: "/telegram",
      bgColor: "telegram-bg-gradient",
    },
  ];
  return (
    <Footer className="we-2-call-homepage-content we-2-call-homepage-footer">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Card className="footer-card">
            <div>
              <img
                src={Images.header_logo}
                alt="Footer Logo"
                className="footer-logo mb-10"
              />
              <div className="footer-heading mb-5">Regd by Costa Rica</div>
              <div className="footer-description mb-5">All Above 18+ Years</div>
              <div className="font-10 footer-link mt-10">
                © 2023 We2Call. All rights reserved.
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16}>
          <Card className="footer-card">
            <div className="footer-content">
              <div className="footer-social-networks-heading mb-10">
                Secure calls with end-to-end encryption
              </div>
              <div className="footer-social-network-description mb-5">
                <span>
                  <MdLockOutline className="privacy-icon" />
                </span>
                <span>
                  Your privacy is our priority. With end-to-end encryption, you
                  can be sure that your conversations stay between you and the
                  person you're talking to.
                </span>
              </div>
              <div className="social-network-name mb-5">Social Networks</div>
              <div className="footer-social-icons mt-10">
                {socialMediaData.map((item, index) => (
                  <div
                    className={`social-icon-container flex-center ${item.bgColor}`}
                    key={index}
                  >
                    <Link to={item.link} className="social-icon">
                      {item.icon}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Footer>
  );
};

export default CustomFooter;
