
import React from "react";  
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { IoMdNotifications } from "react-icons/io";
import { RiLockPasswordLine } from "react-icons/ri";
import { FaUserEdit } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { useState, useEffect } from "react";
import { Images } from "../../images";
import LoginPopup from "../../pages/login/LoginPopup";
import EditProfile from "../../pages/pop-ups/EditProfile";
import SigninModal from "../../pages/pop-ups/SigninModal";
import ResetPassword from "../../pages/pop-ups/ResetPassword";
import ShareDetailsPopup from "../../pages/pop-ups/ShareDetailsPopup";
import { GET_ALL_NOTIFICATIONS, GET_USER_INFO } from "../../api/endpoints";
import { call } from "../../api/axios";
import { AiOutlineShareAlt } from "react-icons/ai";
import ThankyouPopup from "../../pages/pop-ups/ThankyouPopup";
const { Header } = Layout;

const CustomHeader = ({ setShowChatPage }) => {
  const register_id = localStorage.getItem("register_id");
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [signinModal, setSigninModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [openThankyouModal, setOpenThankyouModal] = useState(false);

  const getAllUsersData = async () => {
    await call(GET_USER_INFO, {
      register_id,
    })
      .then((res) => {
        setClientsData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllUsersData();
  }, [refresh]);

  const handleLoginModalOpen = () => {
    setLoginModal(true);
  };

  useEffect(() => {
    register_id === null ? setLoginModal(true) : setLoginModal(false);
  }, []);

  const handleEditProfile = () => {
    setEditProfileModal(!editProfileModal);
    setShowChatPage(false);
    setResetPasswordModal(false);
    setOpenShareModal(false);
  };
  const items = [
    { label: "HOME", path: "/" },
    { label: "CHAT", path: "" },
    { label: "MEETINGS", path: "/meetings" },
  ];

  const handleResetPassword = () => {
    setEditProfileModal(false);
    setResetPasswordModal(true);
    setShowChatPage(false);
    setOpenShareModal(false);
  };
  const handleShareButton = () => {
    setEditProfileModal(false);
    setOpenShareModal(true);
    setShowChatPage(false);
    setResetPasswordModal(false);
  };
  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
    window.location.reload("");
  };

  const [notifications, setnotifications] = useState([]);
  const getNotifications = async () => {
    const payload = {
      register_id: "company",
    };
    await call(GET_ALL_NOTIFICATIONS, payload)
      .then((res) => {
        const arr = res?.data?.data;
        setnotifications(arr);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleNavigate = (item, index) => {
    setActiveKey(index);
    if (index !== 1) {
      navigate(item?.path);
      setShowChatPage(false);
    } else {
      setShowChatPage((prev) => !prev);
    }
  };
  return (
    <div
      className="position-fixed"
      onClick={register_id ? null : () => handleLoginModalOpen()}
    >
      <Header
        className={`we-2-call-header ${loginModal ? "overlay-active" : ""}`}
      >
        <div
          className="header-logo cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={Images.header_logo} alt="we2-call-logo" />
        </div>
        <Menu mode="horizontal" className="navigation-menu flex-center w-50">
          {items?.map((item, index) => (
            <Menu.Item key={index} onClick={() => handleNavigate(item, index)}>
              <div className={activeKey === index ? "clr-yellow" : "clr-white"}>
                {item?.label}
              </div>
            </Menu.Item>
          ))}
        </Menu>
        <div className="w-30 align-center justify-content-end">
          <div className="flex-center font-18 fw-600 pr-10">
            {clientsData?.user_name}
          </div>
          <div className="header-avatar flex-center ml-10 mr-10">
            <img
              src={clientsData?.profile_image}
              alt="profile"
              className="header-avatar"
            />
          </div>
          <div
            className="header-icons flex-center ml-10"
            onClick={() => handleEditProfile()}
          >
            <FaUserEdit />
          </div>
          <div
            className="header-icons flex-center ml-10"
            onClick={() => handleResetPassword()}
          >
            <RiLockPasswordLine />
          </div>
          <div
            className="header-icons flex-center ml-10"
            onClick={() => handleShareButton()}
          >
            <AiOutlineShareAlt />
          </div>
          <div
            className="header-icons flex-center ml-10"
            onClick={() => handleLogout()}
          >
            <IoMdLogOut />
          </div>
        </div>
      </Header>
      <div className="d-flex scrolling-text w-100vw">
        <div className="header-icons notification-icon flex-center ml-10">
          <IoMdNotifications size={18} className="notification-icon" />
        </div>
        <Marquee speed={15}>
          {notifications?.map((obj, index) => (
            <div key={index}>
              {obj?.status === true && (
                <li className="notifi">
                  {obj?.notification_type === "user" && obj?.description}
                </li>
              )}
            </div>
          ))}
        </Marquee>
      </div>
      <EditProfile
        editProfileModal={editProfileModal}
        setEditProfileModal={setEditProfileModal}
        setRefresh={setRefresh}
        setShowChatPage={setShowChatPage}
        clientsData={clientsData}
        setOpenThankyouModal={setOpenThankyouModal}
      />
      <ThankyouPopup
        displayData={"Your Successfully Updated Your Profile"}
        setOpenThankyouModal={setOpenThankyouModal}
        openThankyouModal={openThankyouModal}
      />
      {register_id === null && (
        <LoginPopup
          loginModal={loginModal}
          setLoginModal={setLoginModal}
          setSigninModal={setSigninModal}
        />
      )}
      <SigninModal signinModal={signinModal} setSigninModal={setSigninModal} />
      <ResetPassword
        setShowChatPage={setShowChatPage}
        resetPasswordModal={resetPasswordModal}
        setResetPasswordModal={setResetPasswordModal}
      />
      <ShareDetailsPopup
        setShowChatPage={setShowChatPage}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
      />
    </div>
  );
};

export default CustomHeader;
