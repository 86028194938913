import React from "react";
import Modal from "antd/es/modal/Modal";
import { useEffect, useState } from "react";
import { UpdatedBaseUrl, Images } from "../../images";
import { Row, Col, Input, Button, Form, Avatar } from "antd";
import { MdEdit } from "react-icons/md";
import "./style.css";
import { call } from "../../api/axios";
import { GENERATE_SIGNED_URL, GET_USER_INFO, UPDATE_PROFILE } from "../../api/endpoints";

const EditProfile = (props) => {
  const { editProfileModal, setEditProfileModal, setOpenThankyouModal } = props;
  const register_id = localStorage?.getItem("register_id");
  const [profileImage, setProfileImage] = useState("");
  const [signedUrl, setSignedUrl] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [errMsg, setErrMsg] = useState({});
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    user_name: "",
    profileImage: "",
  });
console.log("changes");
  const getProfile = async (id) => {
    try {
      const res = await call(GET_USER_INFO, { register_id });
      const userProfileData = res?.data?.data;
      const profileImage = userProfileData?.profile_image || "";
      if (profileImage) {
        localStorage.setItem("profileImage", profileImage);
      }
      setFormData({
        first_name: userProfileData?.first_name || "",
        last_name: userProfileData?.last_name || "",
        email: userProfileData?.email || "",
        mobile_no: userProfileData?.mobile_no || "",
        user_name: userProfileData?.user_name || "",
        // profileImage: userProfileData?.profile_image || "",
      });
      setProfileImage(userProfileData?.profile_image);
    } catch (err) {
      console.log(err);
    }
  };

  const generateSignedUrl = async () => {
    setIsProcessing(true);
    await call(GENERATE_SIGNED_URL, {
      register_id,
      event_type: "user_profile_image",
      folder_name: "user_messages",
    })
      .then((res) => {
        setIsProcessing(false);
        const url = res?.data?.data?.result?.signed_url;
        setSignedUrl(url);
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleChange = (e) => {
    if (e.target.name === "mobile_no" && e.target.value.length > 10) {
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleUploadFileSelect = (e) => {
  //   const file = e.target.files[0];
  //   setProfileImage(file);
  //   generateSignedUrl();
  // };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    setIsProcessing(true);
    if (signedUrl && profileImage) {
      await fetch(signedUrl, {
        method: "PUT",
        body: profileImage,
        headers: {
          "Content-Type": "image/jpeg",
          "cache-control": "public, max-age=0",
        },
      })
        .then((res) => {
          setIsProcessing(false);
          console.log(res);
        })
        .catch((err) => console.log(err));
    }

    await call(UPDATE_PROFILE, {
      register_id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      mobile_no: formData.mobile_no,
      user_name: formData.user_name,
      profile_image: `${UpdatedBaseUrl}/profile-images/${register_id}.png`,
      profile_image: profileImage,
      account_role: localStorage?.getItem("account_role"),
    })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setIsProcessing(false);
          setOpenThankyouModal(true);
          window.location.reload();
          setTimeout(() => {
            setOpenThankyouModal(false);
            setEditProfileModal(false);
          }, 1000);
        }
      })
      .catch((err) => {
        setIsProcessing(false);
        console.log(err);
      });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    if (!formData?.first_name || !formData?.last_name || !formData?.user_name) {
      newErrors.errorMessage = "Please enter required fields";
      isValid = false;
    }
    if (!/^\S+@gmail\.com$/.test(formData.email)) {
      newErrors.email = "Please enter a Gmail address";
      isValid = false;
    }
    if (!/^[0-9]{10}$/.test(formData.mobile_no)) {
      newErrors.mobile_no = "Mobile number is invalid";
      isValid = false;
    }
    setErrMsg(newErrors);
    return isValid;
  };

  const handleCancel = () => {
    setEditProfileModal(false);
  };

  const handleUploadFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        generateSignedUrl(register_id);
      };
      reader.readAsDataURL(file);
      console.log("profileImage", profileImage);
    }
  };

  return (
    <Modal className="edit-profile-modal" centered open={editProfileModal} footer={null} onCancel={handleCancel}>
      <div>
        <div className="flex-column flex-center">
          <h2 className="mb-5 edit-info-heading">Edit Your Information</h2>
          <div className="flex-center position-relative cursor-pointer">
            <img src={profileImage || Images.CricketBall} alt="Profile" className="edit-profile-image" />
            <label htmlFor="upload-button">
              <Avatar icon={<MdEdit />} className="edit-profile-icon" />
            </label>
            <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleUploadFileSelect} />
          </div>
          {/* <div className="flex-center position-relative cursor-pointer">
            <img src={profileImage} alt="Profile" className="edit-profile-image" />
            <label htmlFor="upload-button">
              <Avatar icon={<MdEdit />} className="edit-profile-icon" />
            </label>
            <input type="file" id="upload-button" style={{ display: "none" }} onChange={handleFileChange} />
          </div> */}
        </div>
        {isProcessing && <div className="loading-spinner"></div>}
        <Form>
          <Row>
            <Col span={24}>
              <Form.Item label="User Name/Full Name" className="mb-5 profile-label" />
              <Input size="large" type="text" className="edit-profile-input" placeholder="Enter User Name" name="user_name" value={formData?.user_name} onChange={handleChange} />
              <div className="clr-red">{errMsg?.user_name}</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-5">
            <Col span={12}>
              <Form.Item label="First Name" className="mb-5 profile-label" />
              <Input size="large" className="edit-profile-input" type="input" placeholder="Enter First Name..." name="first_name" value={formData?.first_name} onChange={handleChange} />
              <div className="clr-red">{errMsg?.first_name}</div>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" className="mb-5 profile-label" />
              <Input size="large" type="input" className="edit-profile-input" placeholder="Enter Last Name" name="last_name" value={formData?.last_name} onChange={handleChange} />
              <div className="clr-red">{errMsg?.last_name}</div>
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-5">
            <Col span={12}>
              <Form.Item label="Email ID" className="mb-5 profile-label" />
              <Input size="large" placeholder="Enter Email ID" type="email" className="edit-profile-input" name="email" value={formData.email} onChange={handleChange} />
              <div className="clr-red">{errMsg?.email}</div>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone No" className="mb-5 profile-label" />
              <Input size="large" placeholder="Enter Phone Number" className="edit-profile-input" type="number" name="mobile_no" value={formData.mobile_no} onChange={handleChange} />
              <div className="clr-red">{errMsg?.mobile_no}</div>
            </Col>
          </Row>
          <Row className="mt-10">
            <Col span={24} className="update-btn-position">
              <Button htmlType="button" className="edit-profile-btn w-100 update-profile-btn cursor-pointer" onClick={handleSubmit} disabled={isProcessing}>
                {isProcessing ? "Processing..." : "Update Profile"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default EditProfile;
