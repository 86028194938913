import { FaPhotoFilm } from "react-icons/fa6";
import { VscSend } from "react-icons/vsc";
import { useState } from "react";
import moment from "moment";

function CallingChat({ messageList,setMessageList, sendMessage }) {
  const [inputText, setInputText] = useState("");
  const userID = localStorage.getItem("register_id");
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };
  const handleSendClick = () => {
    if (inputText.trim() === "") return;
      const message = {
          ID: "zego" + userID + Date.now(),
          name: "Me",
          time: moment().format("hh:mm A"),
          content: inputText.trim(),
          type: "sent",
        };
      setMessageList((prevMessages) => [...prevMessages, message]);
      sendMessage(inputText.trim()); 
      setInputText("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendClick();
    }
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };
  return (
    <div>
      <div className="chat-div small-font">
        {/* {messageList.map((message, index) => (
          <>
            <div className="d-flex align-items-end flex-column mt-1">
              <div className="w-60">
                <span className="me-1 fw-600">{message?.sender}</span>
                <span>{message?.time}</span>
                <div className="fw-600">{message?.text}</div>
              </div>
            </div>
            
            <div className="d-flex mt-1">
              <img className="send-pic me-1" src={Images?.dhoni_image} alt="" />
              <div className="w-50">
                <span className="me-1 fw-600">Srinivas (Master)</span>
                <span className="">09:34 pm</span>
                <div className="fw-600">{message?.text}</div>
              </div>
            </div>
          </>
        ))} */}
        {messageList.map((message) =>
                    message.type === "sent" ? (
                      <div className="chat-message-rights">
                        <div className="message-right">
                          <div className="message-text">
                            {message.content}
                            <span className="message-time-right">{message.time}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="chat-message">
                        <div className="message-left">
                          <span className="message-user">{message.name}</span>
                          <div className="message-text">
                            {message.content}
                            <span className="message-time">{message.time}</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
      </div>
      <div className="w-100 d-flex align-items-center font-1rem">
       
        <span className="w-100 flex-between msgSendArea send-msg-bg p-1 webkit-fill">
          <input
            className="w-90 all-none2 clr-white"
            placeholder="Enter Message..."
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <VscSend
            className="chat-icon cursor-pointer"
            onClick={handleSendClick}
          />
        </span>
      </div>
    </div>
  );
}

export default CallingChat;
