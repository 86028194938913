import { useEffect, useState } from "react";
import { call } from "../config/axios";
import {
  FANCY_RESULT_PROFIT_LOSS,
  GET_ACCOUNT_MATCHES_DATA,
  GET_FANCY_ENTRY_DATA,
  GET_MATCH_ENTRY_DETAILS,
  GET_MATCH_POSITION_DATA,
} from "../config/endpoints";
import "./styles.css";

function MatchPosition(props) {
  const { matchId, liveMeeting } = props;
  const userName = localStorage.getItem("user_name");
  const [userMatchEntrys, setUserMatchEntrys] = useState([]);
  const [matchPositionData, setMatchPositionData] = useState([]);
  const [userFancyEntrys, setUserFancyEntrys] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [matchRegisterData, setMatchRegisterData] = useState([]);

  const getMatchEntryDetails = async () => {
    await call(GET_MATCH_ENTRY_DETAILS, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setUserMatchEntrys(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFancyEntryDetails = async () => {
    await call(GET_FANCY_ENTRY_DATA, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setUserFancyEntrys(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFancyProfitLoss = async (ID) => {
    await call(FANCY_RESULT_PROFIT_LOSS, {
      register_id: localStorage.getItem("creator_id"),
      registered_match_id: matchRegisterData?.registered_match_id,
    })
      .then((res) => {
        setProfitLossData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getMatchInfo = async () => {
    await call(GET_ACCOUNT_MATCHES_DATA, {
      register_id: localStorage.getItem("creator_id"),
      match_id: liveMeeting?.match_id,
    })
      .then(async (res) => {
        let temp =
          res?.data && res.data.data && res.data.data[0]
            ? res.data.data[0]
            : res.data.data;
            console.log(res.data,'res.data')
        setMatchRegisterData(temp);
      })
      .catch((err) => console.log(err));
  };

  const getMatchPositionData = async () => {
    await call(GET_MATCH_POSITION_DATA, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setMatchPositionData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMatchInfo();
  }, [liveMeeting]);

  useEffect(() => {
    getMatchEntryDetails();
  }, []);

  useEffect(() => {
    getFancyEntryDetails();
  }, []);

  useEffect(() => {
    getFancyProfitLoss();
    getMatchPositionData();
  }, [matchRegisterData]);

  let teamOnePLData = 0,
    teamTwoPLData = 0;

  const matchPositionDataTotal =
    matchPositionData?.length > 0 &&
    matchPositionData
      ?.filter((item) => item?.client_name === userName)
      ?.map(
        (item) => (
          (teamOnePLData += +item?.teamObj[matchRegisterData?.team1]),
          (teamTwoPLData += +item?.teamObj[matchRegisterData?.team2])
        )
      );

  return (
    <div className="w-100 header-bg rounded p-1 font-14">
      <div className="fw-600 num-btnlight-bg rounded p-2 mt-1">
        {userName} - Match Position
      </div>
      <div className="num-btn-bg rounded mt-1">
        {console.log(matchRegisterData,'matchRegisterData')}
        <div className="fw-600 d-flex align-items-center justify-content-around p-1">
          <div className="flex-column flex-center">
            <div>{matchRegisterData?.team1}</div>
            <div className={`${teamOnePLData > 0 ? "green-clr" : "red-clr"}`}>
              {teamOnePLData}
            </div>
          </div>
          <div className="flex-column flex-center ">
            <div>{matchRegisterData?.team2}</div>
            <div className={`${teamTwoPLData > 0 ? "green-clr" : "red-clr"}`}>
              {teamTwoPLData}
            </div>
          </div>
        </div>
        <hr className="sb-line" />
        <div className="w-100 fw-600 d-flex p-1 justify-content-around">
          <div className="col flex-center">S.NO</div>
          <div className="col flex-center">TEAM</div>
          <div className="col flex-center">P/E</div>
          <div className="col flex-center">AMOUNT</div>
        </div>
        <hr className="sb-line" />
        <div className="user-list-height">
          {userMatchEntrys?.length > 0 &&
            userMatchEntrys
              ?.filter((item) => item?.client_name === userName)
              ?.map((item, index) => (
                <>
                  <div className="w-100 d-flex p-1" key={index}>
                    <div className="col flex-center">{index + 1}</div>
                    <div className="col flex-center">{item?.team}</div>
                    <div className="col flex-center">{item?.pe}</div>
                    <div className="col flex-center">{item?.amount}</div>
                  </div>
                  <hr className="sb-line" />
                </>
              ))}
        </div>
      </div>
      <div className="fw-600 num-btn-bg rounded p-2 mt-1">
        <div>
          Fancy P/L = &nbsp;
          <span style={{background:"#082051",padding:"5px",paddingRight:"20px"}}>
            {profitLossData?.clientsData?.[userName]?.totalLossOrProfit || 0}
          </span>
        </div>
      </div>
      <div className="num-btn-bg rounded mt-1" style={{position:"relative"}}>
        <div className="bg-yellow-1 fit-content px-2" >1st Inn</div>
        <div className="w-100 d-flex p-1 justify-content-around" style={{height:"4rem",overflowX:"scroll",marginLeft:"18px"}}>
          <div className="col flex-column flex-center">
            <div>5 Over</div>
            <div>100000</div>
          </div>
          <div className="col flex-column flex-center">
            <div>10 Over</div>
            <div>150000</div>
          </div>
          <div className="col flex-column flex-center">
            <div>15 Over</div>
            <div>200000</div>
          </div>
          <div className="col flex-column flex-center">
            <div>20 Over</div>
            <div>250000</div>
          </div>
        </div>
        <hr className="sb-line" />
        <div className="w-100 fw-600 d-flex p-1 justify-content-around">
          <div className="col flex-center">OVER</div>
          <div className="col flex-center">TEAM</div>
          <div className="col flex-center">RUNS</div>
          <div className="col flex-center">Y/N</div>
          <div className="col flex-center">AMOUNT</div>
        </div>
        <hr className="sb-line" />
        <div className="user-list-height ">
          {userFancyEntrys?.length > 0 &&
            userFancyEntrys
              ?.filter((item) => item?.client_name === userName)
              ?.map((item, index) => (
                <>
                  <div className="w-100 d-flex p-1" key={index}>
                    <div className="col flex-center">{item?.over}</div>
                    <div className="col flex-center">{item?.team}</div>
                    <div className="col flex-center">{item?.runs}</div>
                    <div className="col flex-center">{item?.yN}</div>
                    <div className="col flex-center">{item?.amount}</div>
                  </div>
                  <hr className="sb-line" />
                </>
              ))}
        </div>
      </div>
    </div>
  );
}

export default MatchPosition;
