import React from "react";
import { Content } from "antd/es/layout/layout";
import CallManagement from "./CallManagement";
import We2CallContent from "./We2CallContent";
import { Col, Row } from "antd";
import OffersAndPromotions from "./OffersAndPromotions";
import TrustedPlatforms from "./TrustedPlatforms";

const MainContent = () => {
  return (
    <Content>
      <div>
        <We2CallContent />
        <div>
          <Row gutter={[16, 16]} style={{ display: "flex" }}>
            <Col lg={6} md={10}>
              <CallManagement title="We2-Call" />
            </Col>
            <Col lg={10} md={14}>
              <OffersAndPromotions title="Offer & Promotions" />
            </Col>
            <Col lg={8} md={24}>
              <TrustedPlatforms title="Trusted Platforms" />
            </Col>
          </Row>
        </div>
      </div>
    </Content>
  );
};

export default MainContent;
