import React from "react";
import { useEffect, useState } from "react";
import { call } from "../../api/axios";
import {
  FANCY_RESULT_PROFIT_LOSS,
  GET_ACCOUNT_MATCHES_DATA,
  GET_FANCY_ENTRY_DATA,
  GET_MATCH_ENTRY_DETAILS,
  GET_MATCH_POSITION_DATA,
  GET_LIVE_MEETING,
} from "../../api/endpoints";
import moment from "moment";

function MatchPosition(props) {
  const { meetingId } = props;
  const userName = localStorage.getItem("user_name");
  const [liveMeeting, setLiveMeeting] = useState({});
  const [userMatchEntrys, setUserMatchEntrys] = useState([]);
  const [matchPositionData, setMatchPositionData] = useState([]);
  const [userFancyEntrys, setUserFancyEntrys] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [matchRegisterData, setMatchRegisterData] = useState([]);

  const getLiveMeeting = async () => {
    await call(GET_LIVE_MEETING, {
      creator_id: localStorage.getItem("creator_id"),
      meeting_id: meetingId,
    }).then((res) => {
      const data = res?.data?.data?.Items[0];
      setLiveMeeting(data);
    });
  };

  const getMatchEntryDetails = async () => {
    await call(GET_MATCH_ENTRY_DETAILS, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setUserMatchEntrys(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFancyEntryDetails = async () => {
    await call(GET_FANCY_ENTRY_DATA, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setUserFancyEntrys(res?.data?.data?.Items);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFancyProfitLoss = async (ID) => {
    await call(FANCY_RESULT_PROFIT_LOSS, {
      register_id: localStorage.getItem("creator_id"),
      registered_match_id: matchRegisterData?.registered_match_id,
    })
      .then((res) => {
        setProfitLossData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const getMatchInfo = async () => {
    await call(GET_ACCOUNT_MATCHES_DATA, {
      register_id: localStorage.getItem("creator_id"),
      match_id: liveMeeting?.match_id,
    })
      .then(async (res) => {
        let temp =
          res?.data && res.data.data && res.data.data[0]
            ? res.data.data[0]
            : res.data.data;
        setMatchRegisterData(temp);
      })
      .catch((err) => console.log(err));
  };

  const getMatchPositionData = async () => {
    await call(GET_MATCH_POSITION_DATA, {
      registered_match_id: matchRegisterData?.registered_match_id,
      register_id: localStorage.getItem("creator_id"),
    })
      .then((res) => {
        setMatchPositionData(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLiveMeeting();
  }, []);

  useEffect(() => {
    getMatchInfo();
  }, [liveMeeting]);

  useEffect(() => {
    getMatchEntryDetails();
    getFancyEntryDetails();
    getFancyProfitLoss();
    getMatchPositionData();
  }, [matchRegisterData]);

  let teamOnePLData = 0,
    teamTwoPLData = 0;

  const matchPositionDataTotal =
    matchPositionData?.length > 0 &&
    matchPositionData
      ?.filter((item) => item?.client_name === userName)
      ?.map(
        (item) => (
          (teamOnePLData += +item?.teamObj[matchRegisterData?.team1]),
          (teamTwoPLData += +item?.teamObj[matchRegisterData?.team2])
        )
      );

  return (
    <div className="header-bg p-10px font-11">
      <div className="num-btn-bg rounded p-10px">
        <div>
          <div className="head-container bg-blue">
            Date : {moment(liveMeeting?.date).format("DD-MM-YYYY")}
          </div>
          <div className="head-container bg-blue mt-1">
            {liveMeeting?.event_name}
          </div>
          <div className="head-container bg-blue mt-1">
            {liveMeeting?.match_name}
          </div>
        </div>
      </div>
      <div className="num-btn-bg rounded p-10px mt-1">
        <div>{userName} - Match Position</div>
      </div>
      <div className="num-btn-bg rounded mt-1 ">
        <div className="d-flex justify-content-around p-10px w-100">
          <div className="flex-column flex-center">
            <div>{matchRegisterData?.team1}</div>
            <div className={`${teamOnePLData >= 0 ? "green-clr" : "red-clr"}`}>
              {teamOnePLData}
            </div>
          </div>
          <div className="flex-column flex-center">
            <div>{matchRegisterData?.team2}</div>
            <div className={`${teamTwoPLData >= 0 ? "green-clr" : "red-clr"}`}>
              {teamTwoPLData}
            </div>
          </div>
        </div>
        <hr className="sb-line" />
        <div className="w-100 d-flex p-10px ">
          <div className="w-25">S.NO</div>
          <div className="w-25">TEAM</div>
          <div className="w-25">P/E</div>
          <div className="w-25">AMOUNT</div>
        </div>
        <hr className="sb-line" />
        <div className="user-list-height">
          {userMatchEntrys?.length > 0 &&
            userMatchEntrys
              ?.filter((item) => item.client_name === userName)
              ?.map((item, index) => (
                <div key={index}>
                  <div className="w-100 d-flex p-10px">
                    <div className="w-25">{index + 1}</div>
                    <div className="w-25">{item?.team}</div>
                    <div className="w-25">{item?.pe}</div>
                    <div className="w-25 yellow-clr">{item?.amount}</div>
                  </div>
                  <hr className="sb-line" />
                </div>
              ))}
        </div>
      </div>
      <div className="num-btn-bg rounded p-10px mt-1">
        <div>
          Fancy PL -
          <span>
            {profitLossData?.clientsData?.[userName]?.totalLossOrProfit || 0}
          </span>
        </div>
      </div>
      <div className="num-btn-bg rounded mt-1">
        <div className="inn-btn">1st Inn</div>
        <div className="w-100 d-flex p-10px justify-content-around">
          <div className="col flex-column flex-center">
            <div>5 Over</div>
            <div>100000</div>
          </div>
          <div className="col flex-column flex-center">
            <div>10 Over</div>
            <div>150000</div>
          </div>
          <div className="col flex-column flex-center">
            <div>15 Over</div>
            <div>200000</div>
          </div>
          <div className="col flex-column flex-center">
            <div>20 Over</div>
            <div>250000</div>
          </div>
        </div>
        <hr className="sb-line" />
        <div className="w-100 d-flex p-10px">
          <div className="w-20">OVER</div>
          <div className="w-20">TEAM</div>
          <div className="w-20">RUNS</div>
          <div className="w-20">Y/N</div>
          <div className="w-20">AMOUNT</div>
        </div>
        <hr className="sb-line" />
        <div className="user-list-height">
          {userFancyEntrys?.length > 0 &&
            userFancyEntrys
              .filter((item) => item.client_name === userName)
              ?.map((item, index) => (
                <div key={index}>
                  <div className="w-100 d-flex p-10px">
                    <div className="w-20">{item?.over}</div>
                    <div className="w-20">{item?.team}</div>
                    <div className="w-20">{item?.runs}</div>
                    <div className="w-20">{item?.yN}</div>
                    <div className="w-20 yellow-clr">{item?.amount}</div>
                  </div>
                  <hr className="sb-line" />
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default MatchPosition;
