// agent endpoints
const USER_LOGIN = "/admin/admin_account_login";
const GET_ALL_CLIENTS = "/offline-management/get_user_list";
const GET_REFFERAL_DATA = "/offline-management/get_refferal_data";
const GET_SETTLEMENT_HISTORY = "/offline-management/get_offline_settlement";
const GET_CLIENTS_DATA = "/offline-users/get-clients-data";
const GET_OFFLINE_CLIENTS = "/offline-management/get_clients_data";
const CREATE_OFFLINE_CLIENT = "/offline-management/create_client";
const GET_OFFLINE_CLIENT_DETAILS = "/offline-management/get_clientid_data";
const UPDATE_OFFLINE_CLIENT = "/offline-management/update_clients";
const GET_ALL_MATCHES = "/offline-management/get_matches_data";
const CREATE_OFFLINE_MATCH = "/offline-management/match_creation";
const GET_OFFLINE_ALL_MATCHES = "/offline-management/get_matches_data";
const CREATE_MATCH_ENTRY = "/offline-management/match_entry";
const GET_ACCOUNT_MATCHES_DATA =
  "/offline-management/get_registered_matches_data";
const GET_MATCH_ENTRY_DETAILS = "/offline-management/get_match_entry";
const UPDATE_MATCH_ENTRY = "/offline-management/update_match_entry";
const UPDATE_MATCH = "/offline-management/update_matchid_data";
const DELETE_MATCH_ENTRY = "/offline-management/delete_match_entry";
const MATCH_DECLARATION = "/offline-management/match_result";
const FANCY_ENTRY_DATA = "/offline-management/fancy_entry";
const FANCY_DECLARATION = "/offline-management/fancy_result";
const UPDATE_FANCY_ENTRY = "/offline-management/update_fancy_entry";
const GET_FANCY_ENTRY_DATA = "/offline-management/get_fancy_entry";
const DELETE_FANCY_ENTRY = "/offline-management/delete_fancy_entry";
const GET_FINANCIAL_STATEMENT_BY_DATE =
  "/offline-management/get_complete_matches";
const ACCOUNT_REGISTERATION = "/account_register";
const GET_ALL_PACKAGES = "/packages/get_packages";
const GET_ALL_MEETINGS = "/meetings/get_all_meetings";
const GET_ADMIN_PACKAGES = "/packages/get_admin_packages";
const GET_ADMIN_PACKAGES_TRACKER_INFO = "/packages/get_package_tracker_list";
const CREATE_PACKAGE_SUBSCRIPTION = "/packages/package_subsciption_ticket";
const OFFLINE_PAYMENT_SETTLEMENT =
  "/offline-management/offline_payment_settlement";
const GET_ONEPAGE_REPORT = "/offline-management/one_page_report";
const GET_UPDATED_MATCHES_DATA = "/offline-management/get_all_matches";
const GET_ALL_ADMINS = "/offline-management/get_user_list";
const BLOCK_UNBLOCK = "/offline-management/active_inactive_users";
const ACTIVE_INACTIVE_OFFLINE_USERS =
  "/offline-management/offline_active_inactive_users";
const UPDATE_PROFILE = "/admin/admin_profile_update_info";
const GET_USER_INFO = "/admin/get_admin_accounts_user_info";
const CREATE_MEETING = "/meetings/create_meeting";
const SIGNAL_RECORDING = "/group_call/signal_recording";
const GENERATE_SIGNED_URL = "/admin/profile_signed_url";
const GET_INDUVISUAL_REPORTS = "/offline-management/get_individual_report";
const GET_INDUVISUAL_REFERRAL_BY =
  "/offline-management/get_individual_reportby_refferal";
const GET_INDUVISUAL_MATCH_REPORT =
  "/offline-management/get_match_resultsby_client_id";
const GET_MATCH_POSITION_DATA = "/offline-management/match_position_get_data";
const GET_OFFLINE_USER_DETAILS = "/offline-management/get_offline_user_details";
const RISK_RUNNING_SESSION = "/offline-management/risk_running_session";
const REQUESTED_PACKAGES = "/packages/get_package_histroy_bypackage_requestid";
const GET_ALL_PACKAGES_APPROVED_HSITORY =
  "/packages/get_all_packages_statement";
const GET_ALL_REASONS = "/settings/get_all_security_questions";
const GET_ALL_ADMIN_PACKAGE_REQUEST = "/packages/get_all_admin_package_request";
const APPROVE_REJECT_FOR_SUBSCRIPTION = "/packages/package_approve_reject";
const DELETE_OFFLINE_CLIENT = "/offline-management/delete_clients";
const FANCY_RESULT_PROFIT_LOSS = "/offline-management/fancy_result_profitlose";
const BULK_PACKAGE_APPROVE_REJECT = "/packages/bulk_package_approve_reject";
const GET_USER_MESSAGES = "/chat/get_user_messages";
const UPDATE_MEETING = "/meetings/update_call_meetings";
const CREATE_REFFERAL = "/offline-management/create_refferal";
const ADD_PAYMENT = "/payment/add_payment";
const UPDATE_PAYMENT_GATEWAY = "/payment/update_payment_gateway_we2call";
const GET_ALL_PAYMENT_GATEWAYS = "/payment/get_payments";
const GET_STATEMENT_BY_MATCH_ID =
  "/offline-management/get_statement_bymatch_id";
const MANAGEMENT_MATCHES = "/offline-management/get_matchesBy_accountRole";
const GET_LIVE_MEETING = "/meetings/get_live_meeting";
const GET_COMPLETED_MATCHES_BY_CLEINT =
  "/offline-management/get_completed_matches_by_client_id";
const PRIVACY_POLICY = "/settings/get_policy";
const GET_LIVE_MATCH_RISK_POSITION =
  "/offline-management/live_match_risk_position";
const SET_ADMIN_OFFLINE_PAYMENT =
  "/offline-management/admin_offline_settlement";
const CHANGE_ADMIN_PASSWORD = "/admin/change_password";
const GET_COMPLETE_MATCHES_BY_CLIENT_NAME =
  "/offline-management/get_statement_matches_by_clientId";
const TOP_WINNERS_LOOSERS = "/admin/top_winner_looser";
const GET_ALL_NOTIFICATIONS = "/settings/get_allNotifications";
const SUMMARY_DATA = "/admin/summary";
const GET_RECORDING_PUBLIC_URL = "/group_call/get_call_recording_public_url";
const DELETE_RECORDING = "/group_call/delete_call_recording";
const REQUEST_JOINED_MEETING = "/JoinRequest";

const DELETE = "DELETE";
const POST = "POST";
const GET = "POST";
const PUT = "PUT";

exports.CHANGE_ADMIN_PASSWORD = {
  url: CHANGE_ADMIN_PASSWORD,
  method: POST,
};
exports.GET_COMPLETE_MATCHES_BY_CLIENT_NAME = {
  url: GET_COMPLETE_MATCHES_BY_CLIENT_NAME,
  method: POST,
};
exports.REQUEST_JOINED_MEETING = {
  method: POST,
  url :REQUEST_JOINED_MEETING
}
exports.GET_OFFLINE_USER_DETAILS = {
  url: GET_OFFLINE_USER_DETAILS,
  method: POST,
};
exports.GET_RECORDING_PUBLIC_URL = {
  url: GET_RECORDING_PUBLIC_URL,
  method: POST,
};
exports.DELETE_RECORDING = {
  url: DELETE_RECORDING,
  method: POST,
};

exports.ACTIVE_INACTIVE_OFFLINE_USERS = {
  url: ACTIVE_INACTIVE_OFFLINE_USERS,
  method: POST,
};

exports.SET_ADMIN_OFFLINE_PAYMENT = {
  url: SET_ADMIN_OFFLINE_PAYMENT,
  method: POST,
};

exports.GET_LIVE_MATCH_RISK_POSITION = {
  url: GET_LIVE_MATCH_RISK_POSITION,
  method: POST,
};

exports.GET_COMPLETED_MATCHES_BY_CLEINT = {
  url: GET_COMPLETED_MATCHES_BY_CLEINT,
  method: POST,
};

exports.GET_STATEMENT_BY_MATCH_ID = {
  url: GET_STATEMENT_BY_MATCH_ID,
  method: POST,
};

exports.CREATE_REFFERAL = {
  url: CREATE_REFFERAL,
  method: POST,
};

exports.FANCY_RESULT_PROFIT_LOSS = {
  url: FANCY_RESULT_PROFIT_LOSS,
  method: POST,
};
exports.UPDATE_MEETING = {
  url: UPDATE_MEETING,
  method: POST,
};
exports.ADD_PAYMENT = {
  url: ADD_PAYMENT,
  method: POST,
};
exports.GET_ALL_PAYMENT_GATEWAYS = {
  url: GET_ALL_PAYMENT_GATEWAYS,
  method: POST,
};
exports.UPDATE_PAYMENT_GATEWAY = {
  url: UPDATE_PAYMENT_GATEWAY,
  method: POST,
};

exports.RISK_RUNNING_SESSION = {
  url: RISK_RUNNING_SESSION,
  method: GET,
};

exports.GET_USER_MESSAGES = {
  url: GET_USER_MESSAGES,
  method: POST,
};

exports.DELETE_OFFLINE_CLIENT = {
  url: DELETE_OFFLINE_CLIENT,
  method: POST,
};

exports.UPDATE_MATCH = {
  url: UPDATE_MATCH,
  method: POST,
};

exports.GET_INDUVISUAL_MATCH_REPORT = {
  url: GET_INDUVISUAL_MATCH_REPORT,
  method: GET,
};

exports.GET_MATCH_POSITION_DATA = {
  url: GET_MATCH_POSITION_DATA,
  method: POST,
};

exports.GET_INDUVISUAL_REFERRAL_BY = {
  url: GET_INDUVISUAL_REFERRAL_BY,
  method: GET,
};

exports.GET_INDUVISUAL_REPORTS = {
  url: GET_INDUVISUAL_REPORTS,
  method: GET,
};

exports.GET_ALL_MEETINGS = {
  url: GET_ALL_MEETINGS,
  method: GET,
};

exports.GET_UPDATED_MATCHES_DATA = {
  url: GET_UPDATED_MATCHES_DATA,
  method: POST,
};

exports.GET_ONEPAGE_REPORT = {
  url: GET_ONEPAGE_REPORT,
  method: POST,
};

exports.OFFLINE_PAYMENT_SETTLEMENT = {
  url: OFFLINE_PAYMENT_SETTLEMENT,
  method: POST,
};

exports.GET_FINANCIAL_STATEMENT_BY_DATE = {
  url: GET_FINANCIAL_STATEMENT_BY_DATE,
  method: POST,
};
exports.GET_USER_INFO = {
  url: GET_USER_INFO,
  method: POST,
};

exports.DELETE_FANCY_ENTRY = {
  url: DELETE_FANCY_ENTRY,
  method: DELETE,
};

exports.GET_FANCY_ENTRY_DATA = {
  url: GET_FANCY_ENTRY_DATA,
  method: POST,
};

exports.FANCY_DECLARATION = {
  url: FANCY_DECLARATION,
  method: POST,
};

exports.UPDATE_FANCY_ENTRY = {
  url: UPDATE_FANCY_ENTRY,
  method: POST,
};

exports.FANCY_ENTRY_DATA = {
  url: FANCY_ENTRY_DATA,
  method: POST,
};

exports.MATCH_DECLARATION = {
  url: MATCH_DECLARATION,
  method: POST,
};

exports.DELETE_MATCH_ENTRY = {
  url: DELETE_MATCH_ENTRY,
  method: DELETE,
};

exports.UPDATE_MATCH_ENTRY = {
  url: UPDATE_MATCH_ENTRY,
  method: POST,
};

exports.GET_MATCH_ENTRY_DETAILS = {
  url: GET_MATCH_ENTRY_DETAILS,
  method: POST,
};

exports.GET_ACCOUNT_MATCHES_DATA = {
  url: GET_ACCOUNT_MATCHES_DATA,
  method: POST,
};

exports.CREATE_MATCH_ENTRY = {
  url: CREATE_MATCH_ENTRY,
  method: POST,
};

exports.GET_OFFLINE_ALL_MATCHES = {
  url: GET_OFFLINE_ALL_MATCHES,
  method: POST,
};

exports.CREATE_OFFLINE_MATCH = {
  url: CREATE_OFFLINE_MATCH,
  method: POST,
};

exports.GET_ALL_MATCHES = {
  url: GET_ALL_MATCHES,
  method: POST,
};

exports.UPDATE_OFFLINE_CLIENT = {
  url: UPDATE_OFFLINE_CLIENT,
  method: POST,
};

exports.GET_OFFLINE_CLIENT_DETAILS = {
  url: GET_OFFLINE_CLIENT_DETAILS,
  method: POST,
};

exports.CREATE_OFFLINE_CLIENT = {
  url: CREATE_OFFLINE_CLIENT,
  method: POST,
};

exports.GET_OFFLINE_CLIENTS = {
  url: GET_OFFLINE_CLIENTS,
  method: POST,
};

exports.USER_LOGIN = {
  url: USER_LOGIN,
  method: POST,
};

exports.GET_ALL_CLIENTS = {
  url: GET_ALL_CLIENTS,
  method: POST,
};

exports.GET_REFFERAL_DATA = {
  url: GET_REFFERAL_DATA,
  method: POST,
};
exports.GET_SETTLEMENT_HISTORY = {
  url: GET_SETTLEMENT_HISTORY,
  method: POST,
};

exports.GET_CLIENTS_DATA = {
  url: GET_CLIENTS_DATA,
  method: POST,
};

exports.ACCOUNT_REGISTERATION = {
  url: ACCOUNT_REGISTERATION,
  method: POST,
};

exports.GENERATE_SIGNED_URL = {
  url: GENERATE_SIGNED_URL,
  method: POST,
};
exports.GET_ALL_PACKAGES = {
  url: GET_ALL_PACKAGES,
  method: POST,
};

exports.GET_ALL_MEETINGS = {
  url: GET_ALL_MEETINGS,
  method: POST,
};

exports.GET_ALL_ADMINS = {
  url: GET_ALL_ADMINS,
  method: POST,
};

exports.BLOCK_UNBLOCK = {
  url: BLOCK_UNBLOCK,
  method: POST,
};

exports.UPDATE_PROFILE = {
  url: UPDATE_PROFILE,
  method: POST,
};

exports.CREATE_PACKAGE_SUBSCRIPTION = {
  url: CREATE_PACKAGE_SUBSCRIPTION,
  method: POST,
};
exports.GET_ADMIN_PACKAGES = {
  url: GET_ADMIN_PACKAGES,
  method: POST,
};
exports.GET_ADMIN_PACKAGES_TRACKER_INFO = {
  url: GET_ADMIN_PACKAGES_TRACKER_INFO,
  method: POST,
};
exports.CREATE_MEETING = {
  url: CREATE_MEETING,
  method: POST,
};

exports.SIGNAL_RECORDING = {
  url: SIGNAL_RECORDING,
  method: POST,
  flag: true,
};
exports.REQUESTED_PACKAGES = {
  url: REQUESTED_PACKAGES,
  method: POST,
};
exports.GET_ALL_PACKAGES_APPROVED_HSITORY = {
  url: GET_ALL_PACKAGES_APPROVED_HSITORY,
  method: POST,
};
exports.GET_ALL_REASONS = {
  url: GET_ALL_REASONS,
  method: POST,
};
exports.GET_ALL_ADMIN_PACKAGE_REQUEST = {
  url: GET_ALL_ADMIN_PACKAGE_REQUEST,
  method: POST,
};
exports.APPROVE_REJECT_FOR_SUBSCRIPTION = {
  url: APPROVE_REJECT_FOR_SUBSCRIPTION,
  method: POST,
};
exports.BULK_PACKAGE_APPROVE_REJECT = {
  url: BULK_PACKAGE_APPROVE_REJECT,
  method: POST,
};
exports.MANAGEMENT_MATCHES = {
  url: MANAGEMENT_MATCHES,
  method: POST,
};
exports.GET_LIVE_MEETING = {
  url: GET_LIVE_MEETING,
  method: POST,
};
exports.PRIVACY_POLICY = {
  url: PRIVACY_POLICY,
  method: GET,
};
exports.TOP_WINNERS_LOOSERS = {
  url: TOP_WINNERS_LOOSERS,
  method: GET,
};
exports.GET_ALL_NOTIFICATIONS = {
  url: GET_ALL_NOTIFICATIONS,
  method: POST,
};
exports.SUMMARY_DATA = {
  url: SUMMARY_DATA,
  method: GET,
};
