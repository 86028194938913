import React, { useState } from "react";
import { FaPhotoFilm } from "react-icons/fa6";
import { VscSend } from "react-icons/vsc";
import { Images } from "../../images";

function VideoChat() {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };
  const handleSendClick = () => {
    if (inputText.trim() === "") return;

    const newMessage = {
      sender: "You",
      time: new Date().toLocaleTimeString(),
      text: inputText.trim(),
    };
    setMessages([...messages, newMessage]);
    setInputText("");
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendClick();
    }
  };
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Selected file:", file);
    }
  };

  return (
    <div className="mt-5 flex-column h-72vh overflow-y-scroll clr-white">
      <hr className="w-100 hr-line" />
      <div className="chat-div font-12">
        {messages.map((message, index) => (
          <>
            <div key={index} className="d-flex align-end justify-content-end mt-5">
              <img className="br-circle chat-image mr-5" src={localStorage?.getItem("profileImage")} alt="" />
              <div>
                <span className="mr-5 fw-600">{message.sender}</span>
                <span className="font-10">{message.time}</span>
                <div className="mt-5 fw-600">{message.text}</div>
              </div>
            </div>
            <div key={index} className="d-flex align-items-center mt-5">
              <img className="br-circle chat-image mr-5" src={Images.profileIcon} alt="" />
              <div>
                <span className="mr-5 fw-600">{message.sender}</span>
                <span className="font-10">{message.time}</span>
                <div className="mt-5 fw-600">{message.text}</div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className="w-100 align-center font-1rem">
        <label htmlFor="fileInput" className="send-pic send-msg-bg br-circle flex-center mr-10 cursor-pointer">
          <FaPhotoFilm className="chat-icon" /> <input id="fileInput" type="file" accept="image/*,video/*" style={{ display: "none" }} onChange={handleFileInputChange} />
        </label>
        <span className="flex-space-between send-msg-bg br-25 p-5 webkit-fill">
          <input className="w-100 all-none p-5 clr-white" placeholder="Enter Message..." value={inputText} onChange={handleInputChange} onKeyPress={handleKeyPress} />
          <span className="chat-icon cursor-pointer" onClick={handleSendClick}>
            <VscSend />
          </span>
        </span>
      </div>
    </div>
  );
}

export default VideoChat;
