
import React from "react";  
import "./styles.css";
import { Images } from "./../../images/index";
import { IoMicOutline, IoMicOffOutline, IoSettingsOutline } from "react-icons/io5";
import { MdOutlineVideocamOff, MdOutlineVideocam, MdOutlineFeaturedVideo } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import { MdOutlineCallEnd } from "react-icons/md";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import MatchPosition from "./MatchPosition";
import { useState } from "react";
import VideoChat from "./VideoChat";

function CallWaiting({ meetingId }) {
  const [videoChat, setVideoChat] = useState(true);
  const [matchPosition, setMatchPosition] = useState(false);
  const [minSidebar, setMinSidebar] = useState(true);
  const [maxSidebar, setMaxSidebar] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isVideoOn, setIsVideoOn] = useState(true);
  const toggleMic = () => {
    setIsMicOn((prevState) => !prevState);
  };
  const toggleVideo = () => {
    setIsVideoOn((prevState) => !prevState);
  };
  const handleChatOpen = () => {
    setVideoChat(true);
    setMatchPosition(false);
  };
  const handleMatchPositionOpen = () => {
    setMatchPosition(true);
    setVideoChat(false);
  };
  const handleOpenMaxSidebar = () => {
    setMaxSidebar(true);
    setMinSidebar(false);
  };
  const handleOpenMinSidebar = () => {
    setMinSidebar(true);
    setMaxSidebar(false);
  };

  return (
    <div className="d-flex h-80vh p-10">
      {minSidebar && (
        <div className="w-min blue-bg mr-5 br-25 p-5 flex-column flex-space-between">
          <div>
            <div onClick={() => handleOpenMaxSidebar()} className="send-pic yellow-clr send-msg-bg br-circle flex-center cursor-pointer">
              <FaChevronRight className="chat-icon" />
            </div>
            <img src={Images?.We_Logo} className="we-img mt-10 cursor-pointer" alt="" />
            <img src={Images?.profileIcon} className="we-img mt-10 cursor-pointer" alt="" />
          </div>
          <div className="send-pic send-msg-bg br-circle flex-center cursor-pointer">
            <IoSettingsOutline className="chat-icon" />
          </div>
        </div>
      )}
      {maxSidebar && (
        <div className="w-25 blue-bg mr-5 br-10 p-5">
          <div className="flex-space-around fw-600 font-14">
            <span className="light-bg p-5 br-25 cursor-pointer" onClick={() => handleMatchPositionOpen()}>
              Match Position
            </span>
            <span className="yellow-btn p-5 br-25 cursor-pointer" onClick={() => handleChatOpen()}>
              Chat
            </span>
            <div onClick={() => handleOpenMinSidebar()} className="p-5 yellow-clr send-msg-bg br-circle flex-center cursor-pointer">
              <FaChevronLeft className="chat-icon" />
            </div>
          </div>
          {matchPosition && <MatchPosition meetingId={meetingId} />}
          {videoChat && <VideoChat />}
        </div>
      )}

      <div className={`blue-bg flex-column flex-space-around ml-5 br-10 ${minSidebar ? "w-100" : "w-75"}`}>
        <div>
          <img className="h-25vh" src={Images?.Call_Waiting} alt="" />
          <h3>Waiting for Joining..</h3>
        </div>
        <div className="d-flex">
          <span onClick={() => toggleMic()} className="light-blue-bg br-5 p-5 flex-center font-2rem mr-10 cursor-pointer">
            {isMicOn ? <IoMicOutline className="font-2rem" /> : <IoMicOffOutline className="font-2rem" />}
          </span>
          <span onClick={() => toggleVideo()} className="light-blue-bg br-5 p-5 flex-center font-2rem mr-10 cursor-pointer">
            {isVideoOn ? <MdOutlineVideocam className="font-2rem" /> : <MdOutlineVideocamOff className="font-2rem" />}
          </span>
          <span className="light-blue-bg br-5 p-5 flex-center font-2rem mr-10 cursor-pointer">
            <MdOutlineFeaturedVideo className="font-2rem" />
          </span>
          <span className="light-blue-bg br-5 p-5 flex-center font-2rem mr-10 cursor-pointer">
            <HiDotsHorizontal className="font-2rem" />
          </span>
          <span className="red-bg br-5 red-btn-pad flex-center font-2rem mr-10 cursor-pointer">
            <MdOutlineCallEnd className="font-2rem" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default CallWaiting;
