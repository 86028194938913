const SIGNUP_USER_API = "/client/signup";
const UPDATE_PROFILE = "/client/user_profile_update_info";
const GET_USER_INFO = "/client/get_client_info";
const GENERATE_SIGNED_URL = "/admin/profile_signed_url";
const GET_USER_MESSAGES = "/chat/get_user_messages";
const GET_ALL_MEETINGS = "/meetings/get_clients_meeting";
const LOGIN_API = "/client/client_account_login";
const GENERATE_TOKEN = "/group_call/signal_recording";
const GET_ALL_POSTERS = "/management/get_all_posters";
const GET_ALL_NOTIFICATIONS = "/settings/get_allNotifications";
const RESET_PASSWORD = "/client/users_reset_password";
const RESET_PASSWORD_API = "/client/users_reset_password";
const GET_ALL_CLIENTS = "/offline-management/get_user_list";
const GET_LIVE_MEETING = "/meetings/get_live_meeting";
const SIGNAL_RECORDING = "/group_call/signal_recording";
const UPDATE_MEETING = "/meetings/update_call_meetings";
const FANCY_RESULT_PROFIT_LOSS = "/offline-management/fancy_result_profitlose";
const GET_ACCOUNT_MATCHES_DATA =
  "/offline-management/get_registered_matches_data";
const GET_FANCY_ENTRY_DATA = "/offline-management/get_fancy_entry";
const GET_MATCH_ENTRY_DETAILS = "/offline-management/get_match_entry";
const GET_MATCH_POSITION_DATA = "/offline-management/match_position_get_data";
const GET_ADMIN_DETAILS = "/admin/get_admin_accounts_user_info";

const POST = "POST";
const GET = "POST";

exports.GET_USER_MESSAGES = {
  url: GET_USER_MESSAGES,
  method: POST,
};

exports.GENERATE_SIGNED_URL = {
  url: GENERATE_SIGNED_URL,
  method: POST,
};

exports.SIGNUP_USER_API = {
  url: SIGNUP_USER_API,
  method: POST,
};

exports.GET_USER_INFO = {
  url: GET_USER_INFO,
  method: GET,
};

exports.GET_ALL_MEETINGS = {
  url: GET_ALL_MEETINGS,
  method: GET,
};
exports.LOGIN_API = {
  url: LOGIN_API,
  method: POST,
};

exports.RESET_PASSWORD_API = {
  url: RESET_PASSWORD_API,
  method: POST,
};

exports.GENERATE_TOKEN = {
  url: GENERATE_TOKEN,
  method: POST,
  flag: true,
};

exports.GET_ALL_POSTERS = {
  url: GET_ALL_POSTERS,
  method: POST,
};

exports.GET_ALL_NOTIFICATIONS = {
  url: GET_ALL_NOTIFICATIONS,
  method: POST,
};

exports.RESET_PASSWORD = {
  url: RESET_PASSWORD,
  method: POST,
};

exports.GET_ALL_CLIENTS = {
  url: GET_ALL_CLIENTS,
  method: POST,
};

exports.GET_LIVE_MEETING = {
  url: GET_LIVE_MEETING,
  method: POST,
};

exports.SIGNAL_RECORDING = {
  url: SIGNAL_RECORDING,
  method: POST,
  flag: true,
};
exports.UPDATE_MEETING = {
  url: UPDATE_MEETING,
  method: POST,
};

exports.UPDATE_PROFILE = {
  url: UPDATE_PROFILE,
  method: POST,
};

exports.FANCY_RESULT_PROFIT_LOSS = {
  url: FANCY_RESULT_PROFIT_LOSS,
  method: POST,
};

exports.GET_ACCOUNT_MATCHES_DATA = {
  url: GET_ACCOUNT_MATCHES_DATA,
  method: POST,
};
exports.GET_FANCY_ENTRY_DATA = {
  url: GET_FANCY_ENTRY_DATA,
  method: POST,
};

exports.GET_MATCH_ENTRY_DETAILS = {
  url: GET_MATCH_ENTRY_DETAILS,
  method: POST,
};

exports.GET_MATCH_POSITION_DATA = {
  url: GET_MATCH_POSITION_DATA,
  method: POST,
};

exports.GET_ADMIN_DETAILS = {
  url: GET_ADMIN_DETAILS,
  method: GET,
};
